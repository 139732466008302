'use client'

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { Textfield } from '@design-system/src/components/Textfield'
import { getStripe } from '@design-system/src/utils/stripe-utils'
import { validEmail } from '@design-system/src/utils/validation-utils'
import { CreateCheckoutSessionForDonationBody } from '@next-public-site/app/api/stripe/create-checkout-session-for-donation/route'
import { API_ROUTES_NEXT_PUBLIC_SITE } from '@next-public-site/src/utils/route-utils'
import { loadStripe } from '@stripe/stripe-js/pure'
import type { Session } from 'next-auth'
import { FC, useCallback, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { postData } from '../../../_utils/rest-utils'
import { BlockPubHeaderMenuCustomPageData } from '../../../_utils/typescript-utils'
import { BlockModal } from '../../BlockModal'
import { DisplayErrorToUser } from '../../DisplayErrorToUser'

type FormInput = {
  email: string
}

export const OneTimeDonation: FC<{
  session: Session | null
  stripePublishableKey: NonNullable<BlockPubHeaderMenuCustomPageData['stripePublishableKey']>
  customerServiceNumber: BlockPubHeaderMenuCustomPageData['customerServiceNumber']
  customCtaButtonVariant: 'primary' | 'secondary'
  customCtaLabel?: string
}> = ({ session, stripePublishableKey, customerServiceNumber, customCtaButtonVariant, customCtaLabel = 'Donate' }) => {
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false)
  const [callInProgress, setCallInProgress] = useState(false)
  const [emailModalIsOpen, setEmailModalIsOpen] = useState(false)
  const userEmail = session?.user?.email

  const makeOneTimeDonation = useCallback(async (email: string) => {
    setErrorMsg('')
    try {
      setCallInProgress(true)
      const { accountId, sessionId } = await postData<
        CreateCheckoutSessionForDonationBody,
        {
          sessionId: string
          accountId: string
        }
      >({
        url: API_ROUTES_NEXT_PUBLIC_SITE.createCheckoutSessionForDonationApiUrl,
        data: {
          email,
        },
      })
      // TODO: loadstripe and getStripe - load this dynamically
      const stripe = await getStripe(accountId, stripePublishableKey, loadStripe)
      if (stripe) {
        stripe.redirectToCheckout({ sessionId })
      } else {
        throw new Error('Failed to load stripe')
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorMsg(
        `An error occurred. Please try again later or contact customer support${customerServiceNumber ? `: ${customerServiceNumber}` : ''}.`,
      )
      setEmailModalIsOpen(false)
      setErrorModalIsOpen(true)
    } finally {
      setCallInProgress(false)
    }
  }, [])

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormInput>({ mode: 'onSubmit' })

  const handleDonationClick = useCallback(async () => {
    if (userEmail) {
      await makeOneTimeDonation(userEmail)
    } else {
      setEmailModalIsOpen(true)
    }
  }, [makeOneTimeDonation, userEmail])

  const onSubmit: SubmitHandler<FormInput> = async data => {
    await makeOneTimeDonation(data.email)
  }

  return (
    <>
      <DisplayErrorToUser errorMsg={errorMsg} isOpen={errorModalIsOpen} setIsOpen={setErrorModalIsOpen} />
      <BlockModal
        openModal={emailModalIsOpen}
        onCloseModalCallback={setEmailModalIsOpen}
        title={'Please Enter Your Email Address'}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomFlex direction="column" gap="2">
            <Controller
              name="email"
              control={control}
              rules={{
                required: 'Email is required.',
                pattern: {
                  value: validEmail,
                  message: 'Please enter a valid email address.',
                },
              }}
              render={({ field }) => (
                <Textfield {...field} fullWidth={true} reactFormErrors={errors} floatingLabel="* Enter Email Address" />
              )}
            />
          </CustomFlex>
          <Button
            variant="primary"
            label="Continue To Donate"
            fullWidth={true}
            css={{ mt: '$6' }}
            buttonType="submit"
            buttonState={callInProgress ? 'waiting' : 'default'}
          />
          {errorMsg && (
            <CustomFlex
              justify="center"
              align="center"
              css={{ p: '$2', borderRadius: '$3', backgroundColor: '$ale', mt: '$6' }}>
              <Text variant="caption" css={{ color: '$aleText' }}>
                {errorMsg}
              </Text>
            </CustomFlex>
          )}
        </form>
      </BlockModal>

      <Button
        css={{
          bp2: {
            ml: '$2',
          },
        }}
        variant={customCtaButtonVariant}
        label={customCtaLabel}
        onClick={handleDonationClick}
        buttonState={callInProgress ? 'waiting' : 'default'}
      />
    </>
  )
}
