'use client'

import { Text } from '@design-system/src/components/Text'
import { Dispatch, FC, SetStateAction } from 'react'

import { BlockModal } from './BlockModal'

export const DisplayErrorToUser: FC<{
  errorMsg: string
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}> = ({ errorMsg, isOpen, setIsOpen }) => {
  return (
    <BlockModal openModal={isOpen} onCloseModalCallback={setIsOpen} title={'An Error Occurred'}>
      <Text>{errorMsg}</Text>
    </BlockModal>
  )
}
