'use client'

import { Button, ButtonProps } from '@design-system/src/components/Button'
import { Checkbox } from '@design-system/src/components/Checkbox'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { Textfield } from '@design-system/src/components/Textfield'
import { dollarString } from '@design-system/src/utils/string-utils'
import { validEmail } from '@design-system/src/utils/validation-utils'
import { BlockPubSubscribeCustomPageData } from 'app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { FC, useCallback, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { css, cva } from 'styled-system/css'

import { SubscriptionPlan, SubscriptionPromo } from '@models/types'

import { onSubscribeToStripePlanFactory } from './blocks/BlockPubSubscribe/SubscribeStripe/subscribe-stripe-utils'

type PromoWithPlan = {
  promo: SubscriptionPromo
  plan: SubscriptionPlan
  priceId: string
}

type FormInput = {
  promoCouponId: string
  planPriceId: string
  email: string
}

export const StripePromos: FC<{
  stripePromotions: SubscriptionPromo[]
  availablePlanPriceIdsOrderedFromMostToLeastImportant: BlockPubSubscribeCustomPageData['stripePlanOrderByPriceId']
  stripePlans: BlockPubSubscribeCustomPageData['stripePlans']
  status: BlockPubSubscribeCustomPageData['status']
  loadStripe: BlockPubSubscribeCustomPageData['loadStripe']
  stripePublishableKey: BlockPubSubscribeCustomPageData['stripePublishableKey']
  createSubscriberWithStripeAccountApiUrl: BlockPubSubscribeCustomPageData['createSubscriberWithStripeAccountApiUrl']
  updateSubscriberWithStripeAccountApiUrl: BlockPubSubscribeCustomPageData['updateSubscriberWithStripeAccountApiUrl']
  userHasASubscription: boolean
  siteType: BlockPubSubscribeCustomPageData['siteType']
}> = ({
  status,
  loadStripe,
  stripePromotions,
  availablePlanPriceIdsOrderedFromMostToLeastImportant,
  stripePlans,
  createSubscriberWithStripeAccountApiUrl,
  updateSubscriberWithStripeAccountApiUrl,
  stripePublishableKey,
  userHasASubscription,
  siteType,
}) => {
  const [submitBtnState, setSubmitBtnState] = useState<ButtonProps['buttonState']>('default')
  const [errorMsg, setErrorMsg] = useState('')

  const promos = availablePlanPriceIdsOrderedFromMostToLeastImportant?.reduce(
    (tot: PromoWithPlan[], curr): PromoWithPlan[] => {
      const plan = stripePlans.find(plan => plan.stripePriceIdMonthly === curr || plan.stripePriceIdYearly === curr)
      if (!plan) return tot
      const promo = stripePromotions.find(
        promo => promo.couponId === plan.promoCouponIdMonthly || promo.couponId === plan.promoCouponIdYearly,
      )
      if (!promo) return tot

      return [
        ...tot,
        {
          promo,
          plan,
          priceId: curr,
        },
      ]
    },
    [] as PromoWithPlan[],
  )

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    register,
    reset,
    formState: { errors, defaultValues },
  } = useForm<FormInput>({
    mode: 'onSubmit',
    defaultValues: {
      promoCouponId: promos?.[0]?.promo.couponId,
      planPriceId: promos?.[0]?.priceId,
    },
  })

  const watchPromoCouponId = watch('promoCouponId')
  const watchPlanPriceId = watch('planPriceId')

  const onSubscribeToPlan = onSubscribeToStripePlanFactory({
    userHasASubscription,
    createSubscriberWithStripeAccountApiUrl,
    updateSubscriberWithStripeAccountApiUrl,
    stripePublishableKey,
    loadStripe,
    setSubmitBtnState,
    setErrorMsg,
    priceId: watchPlanPriceId,
    couponId: watchPromoCouponId,
  })

  const onSubmit: SubmitHandler<FormInput> = async formData => {
    const selectedPlan = stripePlans.find(
      plan => plan.stripePriceIdMonthly === formData.planPriceId || plan.stripePriceIdYearly === formData.planPriceId,
    )
    const planFrequency = selectedPlan?.stripePriceIdMonthly === formData.planPriceId ? 'monthly' : 'yearly'
    const clickSubscribeLinkOverride =
      planFrequency === 'monthly'
        ? selectedPlan?.clickSubscribeLinkOverrideMonthly
        : selectedPlan?.clickSubscribeLinkOverrideYearly
    if (clickSubscribeLinkOverride) {
      // simulate click <a href={clickSubscribeLinkOverride} />
      window.location.href = clickSubscribeLinkOverride
    } else {
      onSubscribeToPlan({ email: formData.email })
    }
  }

  const onCheckboxClick = useCallback(
    (promo: PromoWithPlan) => () => {
      reset({
        promoCouponId: promo.promo.couponId,
        planPriceId: promo.priceId,
      })
    },
    [setValue, reset],
  )

  const isUserUnauthenticated = status === 'unauthenticated'

  if (!promos || promos.length === 0) return null

  return (
    <Gutter
      css={{
        backgroundColor: '$gs12',
      }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={css({ mx: '$auto', width: '[fit-content]', position: 'relative' })}>
        <Text variant="body2" css={saleTextStyles}>
          Sale
        </Text>
        <CustomFlex direction="column" gap="2" css={{ maxWidth: '[400px]', pt: '$4' }} justify={'center'}>
          {promos.map(promo => {
            const isChecked = watchPromoCouponId === promo.promo.couponId && watchPlanPriceId === promo.priceId
            const promoTextVariables = getPromoTextVariables(promo)
            if (!promoTextVariables) return null

            return (
              <Checkbox
                key={promo.priceId}
                textVariant="body2"
                text={
                  <CustomFlex direction="column" gap="1">
                    <Text variant="body1">
                      Enjoy <span>{promoTextVariables.percentageOff}% savings</span> on {promoTextVariables.planName}{' '}
                      for the first {promoTextVariables.duration} {promoTextVariables.frequencyText}
                    </Text>
                    <Text variant="body2">
                      <span className={css({ textDecoration: 'line-through', color: '$gs8 !' })}>
                        {dollarString.format(promoTextVariables.normalPrice / 100)}
                      </span>{' '}
                      {dollarString.format(promoTextVariables.promoPrice / 100)} / {promoTextVariables.frequencyUnit}
                    </Text>
                  </CustomFlex>
                }
                iconType="indeterminate"
                checked={isChecked}
                onChange={onCheckboxClick(promo)}
                className={checkBoxStyles({
                  checked: isChecked,
                })}
              />
            )
          })}
          {isUserUnauthenticated && (
            <Controller
              name="email"
              control={control}
              rules={{
                required: {
                  value: isUserUnauthenticated,
                  message: 'Email address is required.',
                },
                pattern: {
                  value: validEmail,
                  message: 'Please enter a valid email address.',
                },
              }}
              render={({ field }) => (
                <Textfield
                  {...field}
                  css={{ mt: '$1' }}
                  fullWidth={true}
                  reactFormErrors={errors}
                  placeholder="* Email Address"
                  inputVariant="body2"
                />
              )}
            />
          )}

          <Button
            label={siteType === 'donation' ? 'Donate' : 'Subscribe now'}
            variant="secondary"
            css={{ mt: '$4' }}
            fullWidth={true}
            buttonType="submit"
            buttonState={submitBtnState}
          />
          {errorMsg && (
            <Text
              variant="caption"
              css={{ bgColor: '$ale', color: '$aleText', px: '$4', py: '$2', borderRadius: '$3', mt: '$2' }}>
              {errorMsg}
            </Text>
          )}
        </CustomFlex>
      </form>
    </Gutter>
  )
}

const getPromoTextVariables = (
  pp: PromoWithPlan,
):
  | {
      normalPrice: number
      promoPrice: number
      percentageOff: number
      duration: number | undefined
      frequencyText: string
      frequencyUnit: 'month' | 'year'
      planName: string
    }
  | undefined => {
  const planFrequency = pp.plan.stripePriceIdMonthly === pp.priceId ? 'monthly' : 'yearly'
  const duration =
    planFrequency === 'yearly' ? undefined : pp.promo.durationInMonths > 1 ? pp.promo.durationInMonths : undefined
  const frequencyText = planFrequency === 'yearly' ? 'year' : duration || 0 > 1 ? 'months' : 'month'
  const frequencyUnit = planFrequency === 'yearly' ? 'year' : 'month'
  const normalPrice =
    planFrequency === 'yearly' ? pp.plan.priceForYearlyToggleChoice : pp.plan.priceForMonthlyToggleChoice
  if (!normalPrice) return undefined
  const promoPrice = normalPrice - (normalPrice * pp.promo.percentageOff) / 100

  return {
    normalPrice,
    promoPrice,
    percentageOff: pp.promo.percentageOff,
    duration,
    frequencyText,
    frequencyUnit,
    planName: pp.plan.name,
  }
}

const saleTextStyles = {
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translateX(-50%)',
  color: '$sec',
  backgroundColor: '$secText',
  px: '$4',
  py: '$1',
  borderRadius: '$round',
  borderStyle: 'solid',
  borderWidth: '$1',
  borderColor: '$sec',
} as const

const checkBoxStyles = cva({
  base: {
    color: '$gs1',
    borderWidth: '$2',
    borderRadius: '$3',
    borderStyle: 'solid',
    px: '$2',
    py: '$5',
    transitionDuration: '$normal',
    transitionProperty: 'border-color',
    _hover: {
      transitionDuration: '$normal',
      transitionProperty: 'border-color',
    },
    '& span': {
      color: '$gs1',
    },
  },
  variants: {
    checked: {
      true: {
        borderColor: '$gs1',
      },
      false: {
        borderColor: '$gs9',
        _hover: {
          borderColor: '$gs8',
        },
      },
    },
  },
})
