import { PlanAdSettings } from '@models/types'

import { GoogleAdProps } from '../_components/GoogleAd'

export const isAdvertisementVisible = ({
  googleAdConfig,
  adSettings,
}: {
  googleAdConfig: GoogleAdProps | undefined
  adSettings?: PlanAdSettings
}): boolean => {
  if (adSettings === 'NoAds') return false
  if (!googleAdConfig) return false
  if (
    googleAdConfig.adSystem === 'custom' &&
    googleAdConfig.customAds &&
    googleAdConfig.customAds.imgHeight &&
    googleAdConfig.customAds.imgWidth &&
    googleAdConfig.customAds.imgSrc
  ) {
    return true
  }

  const showGTM =
    googleAdConfig.adSystem === 'googleAdManager' &&
    googleAdConfig.gtmUnitCode &&
    googleAdConfig.gtmSizes &&
    googleAdConfig.gtmMinWidth &&
    googleAdConfig.gtmMinHeight &&
    googleAdConfig.gtmDivId

  if (showGTM) {
    return true
  }

  return false
}
