import { Box } from '@design-system/src/components/Box'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Icon } from '@design-system/src/components/Icon'
import { ShoImage } from '@design-system/src/components/ShoImage'
import { Text } from '@design-system/src/components/Text'
import { truncateText } from '@design-system/src/utils/string-utils'
import { VideoIcon } from '@radix-ui/react-icons'
import { ArticlePreviewVerticalSmallType } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import Link from 'next/link'
import React from 'react'
import { roundNumberToXDecimals } from 'src/utils/helpers'
import { css, cva } from 'styled-system/css'

import { ImagePlaceholder } from './ImagePlaceholder'
import { SectionAndDatePreview } from './SectionAndDatePreview'

export const ArticlePreviewVerticalSmall: React.FC<ArticlePreviewVerticalSmallType> = ({
  sectionName,
  sectionHref,
  articleHref,
  date,
  title,
  articleSrc,
  hasVideo = false,
  css: cssProp = {},
  imageHeight,
  imageWidth,
  imgPlaceholder,
  imgPlaceholderWidth,
  imgPlaceholderHeight,
  hideImage = false,
  blurryPlaceholder,
  makeTitleTextSmall = false,
  sizes = '(min-width: 77.5em) 20vw, (min-width: 56.5625em) 50vw, (min-width: 77.5em) 300px, 100vw',
  truncateTitle = true,
}) => {
  return (
    <Box css={cssProp}>
      <Box>
        {!hideImage && (
          <>
            <Box
              css={css.raw({
                mb: '$6',
                position: 'relative',
                backgroundColor: '$gs7',
                borderRadius: '$3',
                overflow: 'hidden',
              })}>
              <Link href={articleHref}>
                {articleSrc && imageWidth && imageHeight ? (
                  <>
                    <ShoImage
                      blurryPlaceholder={blurryPlaceholder}
                      src={articleSrc}
                      fill={true}
                      imageCss={css.raw({
                        borderRadius: '$3',
                        objectFit: 'cover',
                      })}
                      className={shoImageStyled({
                        ratio:
                          imageWidth === imageHeight
                            ? 'square'
                            : imageWidth > imageHeight
                              ? roundNumberToXDecimals(imageWidth / imageHeight) >= roundNumberToXDecimals(16 / 9)
                                ? 'wide_landscape'
                                : 'landscape'
                              : 'portrait',
                      })}
                      alt={title}
                      sizes={sizes}
                    />
                    {hasVideo && (
                      <Icon
                        reactIcon={<VideoIcon />}
                        css={{
                          color: '$gs12',
                          padding: '$5',
                          borderRadius: '[50%]',
                          backgroundColor: '$w2',
                          opacity: 0.7,
                          position: 'absolute',
                          cursor: 'pointer',
                          right: '[16px]',
                          bottom: '[16px]',
                        }}
                      />
                    )}
                  </>
                ) : (
                  <ImagePlaceholder src={imgPlaceholder} width={imgPlaceholderWidth} height={imgPlaceholderHeight} />
                )}
              </Link>
            </Box>
          </>
        )}
      </Box>

      <SectionAndDatePreview
        sectionName={sectionName}
        sectionHref={sectionHref}
        date={date}
        css={{ mb: '$2' }}
        colorVariant="color"
      />
      <CustomFlex>
        <Link href={articleHref}>
          <Text
            variant={makeTitleTextSmall ? 'body2' : 'body1'}
            css={{
              color: '$gs11',
              width: '$full',
              _hover: {
                transitionProperty: 'opacity',
                transitionDuration: '$normal',
                transitionTimingFunction: 'in-out',
                opacity: '0.8',
              },
            }}>
            {truncateTitle ? truncateText(title, 60) : title}
          </Text>
        </Link>
      </CustomFlex>
    </Box>
  )
}

const shoImageStyled = cva({
  base: {
    width: '$full',
    transitionDuration: '$slow',
    transitionProperty: 'all',
    transitionTimingFunction: 'out',
    _hover: {
      transitionProperty: 'all',
      transitionDuration: '$slow',
      transitionTimingFunction: 'in',
      transform: 'scale(1.03)',
    },
  },
  variants: {
    ratio: {
      square: {
        paddingBottom: '[100%]',
      },
      landscape: {
        paddingBottom: '[75%]',
      },
      wide_landscape: {
        paddingBottom: '[56.25%]',
      },
      portrait: {
        paddingBottom: '[133.33%]',
      },
    },
  },
  defaultVariants: {
    ratio: 'square',
  },
})
