'use client'

import { Gutter } from '@design-system/src/components/Gutter'
import { useSession } from 'next-auth/react'
import { FC } from 'react'

import { isAdvertisementVisible } from '../../../_utils/ad-utils'
import { adSystemOfSubscription } from '../../../_utils/publication-utils'
import { BlockPubArticleRelatedArticlesCustomPageData } from '../../../_utils/typescript-utils'
import { GoogleAd } from '../../GoogleAd'

const collapsableId = 'articles-related-articles-ad'

export const AdSection: FC<BlockPubArticleRelatedArticlesCustomPageData> = props => {
  const { data: session, status } = useSession()
  const { googleAdConfig, stripePlans, stripeSupportPlans, connectedSubscriptionSystem } = props
  const adSettings = adSystemOfSubscription({
    status,
    session,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  })
  const advertisementIsVisible = isAdvertisementVisible({ googleAdConfig, adSettings })

  return advertisementIsVisible ? (
    <Gutter
      css={{
        bgColor: '$gs1',
        overflowX: 'auto',
        bp3: {
          display: 'block',
        },
      }}
      id={collapsableId}>
      <GoogleAd {...googleAdConfig} collapsableId={collapsableId} />
    </Gutter>
  ) : null
}
