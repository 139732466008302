'use client'

import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { useSession } from 'next-auth/react'
import React from 'react'

import { isAdvertisementVisible } from '../../_utils/ad-utils'
import { adSystemOfSubscription } from '../../_utils/publication-utils'
import { BlockGoogleAdBlockCustomPageData, BlockViewProps } from '../../_utils/typescript-utils'
import { GoogleAd } from '../GoogleAd'

export const BlockGoogleAd: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockGoogleAdBlockCustomPageData
  }>
> = props => {
  const { data: session, status } = useSession()
  const { googleAdConfig, connectedSubscriptionSystem, stripePlans, stripeSupportPlans } = props.blockCustomData
  const adSettings = adSystemOfSubscription({
    status,
    session,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  })
  const advertisementIsVisible = isAdvertisementVisible({ googleAdConfig, adSettings })
  const collapsableId = `google-ad-${props.blockId}`

  return advertisementIsVisible ? (
    <div data-sho-block-name="BlockGoogleAd" data-sho-block-id={props.blockId} id={collapsableId}>
      <Gutter modificiations="noBottomPadding" css={{ pb: '$5' }}>
        <CustomFlex direction="column" gap={'5'}>
          <CustomDivider orientation="horizontal" size="medium" css={{ color: '$gs7' }} />
          <Text variant="overline" oll={false} css={{ color: '$gs11', textAlign: 'center' }}>
            Advertisement
          </Text>
        </CustomFlex>
      </Gutter>
      <Gutter variant={'horizontal'} css={{ overflow: 'auto', mb: '$6', bp2: { mb: '$8' }, bp3: { mb: '$10' } }}>
        <GoogleAd {...props.blockCustomData.googleAdConfig} collapsableId={collapsableId} />
      </Gutter>
    </div>
  ) : null
}
