import { FC } from 'react'
import { css, cva } from 'styled-system/css'

import { IframeElement } from '@models/wysiwyg-types'

import { CustomDivider } from '../CustomDivider'
import { Tooltip } from '../Tooltip'
import { ExternalImage, ImageAsset, LocalImage } from './Image'
import { WysiwygLink } from './Link'
import { SlateElementType } from './types'

export const Element: FC<
  SlateElementType & {
    onOpenImageModal?: (isOpen: boolean) => void
    onOpenIframeModal?: (isOpen: boolean) => void
    onOpenLinkModal?: (isOpen: boolean) => void
  }
> = ({ attributes, children, element, isInEditMode, onOpenImageModal, onOpenIframeModal, onOpenLinkModal }) => {
  switch (element.type) {
    case 'bulleted-list':
      return (
        <ul
          data-wisiwig-bulleted-list
          {...attributes}
          className={css(listStyle, { listStyleType: 'disc', mb: '1.5em' })}>
          {children}
        </ul>
      )

    case 'list-item':
      return (
        <li
          data-wisiwig-list-item
          {...attributes}
          className={css({
            txtStyle: 'body1',
          })}>
          {children}
        </li>
      )

    case 'numbered-list':
      return (
        <ol data-wisiwig-numbered-list {...attributes} className={css(listStyle, { listStyleType: 'decimal' })}>
          {children}
        </ol>
      )

    case 'code':
      return (
        <pre data-wisiwig-code {...attributes}>
          <code
            className={css({
              txtStyle: 'code',
              backgroundColor: '$gs12',
              color: '$gs1',
              display: 'block',
              whiteSpace: 'pre-wrap',
              overflowWrap: 'break-word',
              marginBottom: 0,
              padding: '6px',
            })}>
            {children}
          </code>
        </pre>
      )

    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'h5':
    case 'h6':
      return (
        <h2
          data-wisiwig-h2
          {...attributes}
          className={css({
            txtStyle: isInEditMode ? 'h5' : 'h3',
            mb: isInEditMode ? '$h5' : '$h3',
          })}>
          {children}
        </h2>
      )

    // case 'h1':
    //   return (
    //     <H1 data-wisiwig-h1 {...attributes} variant="h1">
    //       {children}
    //     </H1>
    //   )

    // case 'h2':
    //   return (
    //     <H2 data-wisiwig-h2 {...attributes} variant="h2">
    //       {children}
    //     </H2>
    //   )

    // case 'h3':
    //   return (
    //     <H3 data-wisiwig-h3 {...attributes} variant="h3">
    //       {children}
    //     </H3>
    //   )

    // case 'h4':
    //   return (
    //     <H4 data-wisiwig-h4 {...attributes} variant="h4">
    //       {children}
    //     </H4>
    //   )

    // case 'h5':
    //   return (
    //     <H5 data-wisiwig-h5 {...attributes} variant="h5">
    //       {children}
    //     </H5>
    //   )

    // case 'h6':
    //   return (
    //     <H6 data-wisiwig-h6 {...attributes} variant="h6">
    //       {children}
    //     </H6>
    //   )

    case 'subtitle1':
      return (
        <span
          data-wisiwig-subtitle1
          {...attributes}
          className={css({
            txtStyle: 'subtitle1',
            mb: '$subtitle1',
          })}>
          {children}
        </span>
      )

    case 'subtitle2':
      return (
        <span
          data-wisiwig-subtitle2
          {...attributes}
          className={css({
            txtStyle: 'subtitle2',
            mb: '$subtitle2',
          })}>
          {children}
        </span>
      )

    case 'paragraph':
      return (
        <p
          data-wisiwig-body1
          {...attributes}
          className={css({
            txtStyle: 'body1',
            mb: '$body1',
          })}>
          {children}
        </p>
      )

    case 'body1':
      return (
        <p
          data-wisiwig-body1
          {...attributes}
          className={css({
            txtStyle: 'body1',
            mb: '$body1',
          })}>
          {children}
        </p>
      )

    case 'body2':
      return (
        <p
          data-wisiwig-body2
          {...attributes}
          className={css({
            txtStyle: 'body2',
            mb: '$body2',
          })}>
          {children}
        </p>
      )

    case 'caption':
      return (
        <p
          data-wisiwig-caption
          {...attributes}
          className={css({
            txtStyle: 'caption',
            mb: '$caption',
          })}>
          {children}
        </p>
      )

    case 'overline':
      return (
        <p
          data-wisiwig-overline
          {...attributes}
          className={css({
            txtStyle: 'overline',
            mb: '$overline',
          })}>
          {children}
        </p>
      )

    case 'blockquote':
      return (
        <blockquote
          data-wisiwig-blockquote
          {...attributes}
          className={css({
            txtStyle: 'blockquote',
            mb: '$blockquote',
          })}>
          {children}
        </blockquote>
      )

    case 'left':
      return (
        <div
          className={css({
            textAlign: 'left',
            listStylePosition: 'inside',
          })}
          {...attributes}>
          {children}
        </div>
      )

    case 'right':
      return (
        <div
          className={css({
            textAlign: 'right',
            listStylePosition: 'inside',
          })}
          {...attributes}>
          {children}
        </div>
      )

    case 'center':
      return (
        <div
          className={css({
            textAlign: 'center',
            listStylePosition: 'inside',
          })}
          {...attributes}>
          {children}
        </div>
      )

    case 'justify':
      return (
        <div
          className={css({
            textAlign: 'justify',
            listStylePosition: 'inside',
          })}
          {...attributes}>
          {children}
        </div>
      )

    case 'table':
      return (
        <table
          className={css({
            borderCollapse: 'collapse',
            width: '$full',
            borderWidth: '$1',
            borderColor: '$gs12',
            borderStyle: 'solid',
          })}>
          {children}
        </table>
      )

    case 'table-body':
      return <tbody {...attributes}>{children}</tbody>

    case 'table-header':
      return (
        <thead className={css({ width: '$full' })} {...attributes}>
          {children}
        </thead>
      )

    case 'table-row':
      return (
        <tr {...attributes} className={css({ width: '$full' })}>
          {children}
        </tr>
      )

    case 'table-footer':
      return (
        <tfoot {...attributes}>
          <tr>{children}</tr>
        </tfoot>
      )

    case 'table-cell':
      return (
        <td {...attributes} colSpan={element.colspan} className={cellStyle}>
          {children}
        </td>
      )

    case 'head-cell':
      return (
        <th {...attributes} colSpan={element.colspan} className={cellStyle}>
          {children}
        </th>
      )

    case 'iframe':
      const { url, ratio } = element as IframeElement

      return (
        <div
          {...attributes}
          {...element}
          onClick={() => {
            onOpenIframeModal?.(true)
          }}>
          <div
            className={iframeWrapper({
              isInEditMode,
              ratio,
            })}>
            <iframe
              frameBorder="0"
              src={url}
              // height={element.height}
              allowFullScreen
            />
          </div>
          {children}
        </div>
      )

    case 'image-asset':
      return (
        <ImageAsset
          attributes={attributes}
          {...element}
          onClick={() => {
            onOpenImageModal?.(true)
          }}>
          {children}
        </ImageAsset>
      )

    case 'image-local':
      return (
        <LocalImage attributes={attributes} {...element}>
          {children}
        </LocalImage>
      )

    case 'image-external':
      return (
        <ExternalImage attributes={attributes} {...element}>
          {children}
        </ExternalImage>
      )

    case 'link':
      return isInEditMode ? (
        <Tooltip content={element.url}>
          <span
            {...attributes}
            {...element}
            className={css({
              color: '$sec',
              cursor: 'pointer',
            })}
            onClick={() => {
              onOpenLinkModal?.(true)
            }}>
            {children}
          </span>
        </Tooltip>
      ) : (
        <WysiwygLink attributes={attributes} {...element}>
          {children}
        </WysiwygLink>
      )

    case 'divider':
      return (
        <div {...attributes}>
          <CustomDivider orientation="horizontal" size="small" css={css.raw({ background: '$gs12' })} />
          {children}
        </div>
      )
    case 'text':
      return (
        <span data-wisiwig-body1 {...attributes} className={css({ txtStyle: 'body1', mb: '$body1' })}>
          {children}
        </span>
      )
    default:
      return (
        <span data-wisiwig-body1 {...attributes} className={css({ txtStyle: 'body1', mb: '$body1' })}>
          {children}
        </span>
      )
  }
}

export const iframeWrapper = cva({
  base: {
    width: '$full',
    position: 'relative',
    maxHeight: '[70vh]',
    '& iframe': {
      position: 'absolute',
      top: '0',
      left: '0',
      width: '$full',
      height: '$full',
      maxHeight: '[70vh]',
      border: 'none',
      borderWidth: '$0',
    },
  },
  variants: {
    ratio: {
      Video: {
        paddingTop: '[56.25%]',
      },
      TikTok: {
        paddingTop: '[177.77%]',
      },
      Spotify: {
        height: '[80px]',
        '@media (min-width: 315px)': {
          height: '[152px]',
        },
        '@media (min-width: 45px)': {
          height: '[232px]',
        },
        '@media (min-width: 686px)': {
          height: '[352px]',
        },
        '@media (min-width: 1240px)': {
          height: '[232px]',
        },
      },
      Square: {
        paddingTop: '[100%]',
      },
      Wide: {
        paddingTop: '[56.25%]',
      },
      Tall: {
        paddingTop: '[177.77%]',
      },
    },
    isInEditMode: {
      true: {
        // add box over iframe to make it clickable
        // and to show the iframe is editable
        position: 'relative',
        _after: {
          content: '""',
          position: 'absolute',
          top: '0',
          left: '0',
          width: '$full',
          height: '$full',
          borderWidth: '$1',
          borderStyle: 'solid',
          borderColor: '$gs12',
          zIndex: '[1]',
        },
      },
    },
  },
  defaultVariants: {
    isInEditMode: false,
    ratio: 'Video',
  },
})

const cellStyle = css({
  borderWidth: '$1',
  borderColor: '$gs3',
  borderStyle: 'solid',
  textAlign: 'left',
  p: '$2',
})

const listStyle = {
  ml: '[40px]',
  mb: '[0.4em]',
}
