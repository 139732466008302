'use client'

import { Box } from '@design-system/src/components/Box'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { Gutter } from '@design-system/src/components/Gutter'
import { ShoImage } from '@design-system/src/components/ShoImage'
import { Text } from '@design-system/src/components/Text'
import { BlockPubExternalReferencesCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React from 'react'
import { css } from 'styled-system/css'

import { HOME_PAGE_INJECTED_SCRIPT_CONTAINER_ID } from '@shared/constants'

import { InjectDangerousHtml } from '../../../_components/InjectDangerousHtml'
import { BlockViewProps } from '../../_utils/typescript-utils'

export const BlockPubExternalReferences: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockPubExternalReferencesCustomPageData
  }>
> = props => {
  if (!props?.blockCustomData) return null // do not remove this, otherwise prodction sites with no external references data will break
  const { externalReferencesItems, snippet } = props?.blockCustomData
  return (
    <>
      {!!snippet && <InjectDangerousHtml html={snippet} id={HOME_PAGE_INJECTED_SCRIPT_CONTAINER_ID} />}
      {!!externalReferencesItems?.length && (
        <div data-sho-block-name="BlockPubExternalReferences" data-sho-block-id={props.blockId}>
          <Gutter variant="horizontal">
            <Box
              css={{
                borderBottomWidth: '$1',
                borderBottomStyle: 'solid',
                borderBottomColor: '$gs12',
              }}
            />
          </Gutter>
          <Gutter>
            <CustomGrid
              columns={{ base: '1', bp1: '4' }}
              gap="none"
              columnGap={{ base: 'small', bp1: 'none' }}
              rowGap="medium"
              css={gridStyled}>
              {externalReferencesItems.map(({ ctaLink, ctaText, image: { width, src, height } }) => (
                <a href={ctaLink} key={ctaLink} target="_blank" rel="noreferrer">
                  {src && width && height && (
                    <ShoImage
                      src={src}
                      width={width}
                      height={height}
                      alt={`external image - ${ctaText}`}
                      imageCss={css.raw({
                        borderRadius: '$3',
                        mx: '[auto]',
                        maxWidth: '[100%]',
                        // maxHeight: '[401px]',
                        width: '[auto]',
                        height: '[auto]',
                      })}
                      sizes="(min-width: 77.5em) 250px, (min-width: 37.5em) cacl(25vw - 50px), calc(100vw - 48px)"
                    />
                  )}

                  {ctaText && (
                    <Text variant="body1" css={{ color: '$gs12', mt: '$2' }}>
                      {ctaText}
                    </Text>
                  )}
                </a>
              ))}
            </CustomGrid>
          </Gutter>
        </div>
      )}
    </>
  )
}

const gridStyled = css.raw({
  overflowX: '[auto]',
  overflowY: '[hidden]',
  marginLeft: '-$5',
  marginRight: '-$5',
  '& > *': {
    paddingLeft: '$5',
    paddingRight: '$5',
    borderLeftWidth: '$1',
    borderLeftStyle: 'solid',
    borderLeftColor: '$gs7',
  },
  '& > *:nth-child(1)': {
    borderLeftWidth: '$0',
  },
})
