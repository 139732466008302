'use client'

import { Box } from '@design-system/src/components/Box'
import { Button } from '@design-system/src/components/Button'
import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { Textfield } from '@design-system/src/components/Textfield'
import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import { dollarString } from '@design-system/src/utils/string-utils'
import { getStripe } from '@design-system/src/utils/stripe-utils'
import { validEmail } from '@design-system/src/utils/validation-utils'
import { loadStripe } from '@stripe/stripe-js/pure'
import { CreateCheckoutSessionForGiftBody } from 'app/api/stripe/create-checkout-session-for-gift/route'
import { FC, useCallback, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { API_ROUTES_NEXT_PUBLIC_SITE } from 'src/utils/route-utils'
import { css, cva } from 'styled-system/css'

import { postData } from '../../../_utils/rest-utils'
import { BlockPubGiftCustomPageData } from '../../../_utils/typescript-utils'
import { DisplayErrorToUser } from '../../DisplayErrorToUser'
import { GetUserEmail } from './GetUserEmail'

type FormInput = {
  email: string
}

export const StarredGiftPlan: FC<
  NonNullable<BlockPubGiftCustomPageData['starredGiftPlan']> & {
    userEmail?: string
    stripePublishableKey: string
    customerServiceNumber?: string
    customerServiceNumberURI?: string
    showFixedBanner: boolean
    authenticationStatus: 'authenticated' | 'loading' | 'unauthenticated'
    variant: 'large' | 'small'
    siteType: BlockPubGiftCustomPageData['siteType']
  }
> = ({
  planSummary,
  benefits,
  name,
  subtitle,
  priceInCents,
  monthsGifted,
  publicationName,
  userEmail,
  priceId,
  stripePublishableKey,
  customerServiceNumber,
  clickSubscribeLinkOverrideMonthly,
  showFixedBanner,
  authenticationStatus,
  variant,
  siteType,
}) => {
  const [userInfoModalIsOpen, setUserInfoModalIsOpen] = useState(false)
  const [callInProgress, setCallInProgress] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false)

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    register,
    reset,
    formState: { errors, defaultValues },
  } = useForm<FormInput>({
    mode: 'onSubmit',
    defaultValues: {
      email: userEmail || '',
    },
  })

  const purchaseGiftPlan = useCallback(
    async ({ email, openErrorModal = true }: { email: string; openErrorModal?: boolean }) => {
      setErrorMsg('')
      try {
        setCallInProgress(true)
        const { accountId, sessionId } = await postData<
          CreateCheckoutSessionForGiftBody,
          {
            sessionId: string
            accountId: string
          }
        >({
          url: API_ROUTES_NEXT_PUBLIC_SITE.createCheckoutSessionForGiftApiUrl,
          data: {
            price: priceId,
            email: email,
          },
        })
        const stripe = await getStripe(accountId, stripePublishableKey, loadStripe)
        if (stripe) {
          stripe.redirectToCheckout({ sessionId })
        } else {
          throw new Error('Failed to load stripe')
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        const message =
          typeof error === 'object' && error?.cause?.name === 'USER_ALREADY_EXISTS'
            ? `${siteType === 'donation' ? 'Donation' : 'Subscription'} with email already exists.`
            : `An error occurred. Please try again later or contact customer support${customerServiceNumber ? `: ${customerServiceNumber}` : ''}.`
        setErrorMsg(message)
        if (openErrorModal) setErrorModalIsOpen(true)
      } finally {
        setCallInProgress(false)
      }
    },
    [],
  )

  const onSubmit: SubmitHandler<FormInput> = form => {
    if (clickSubscribeLinkOverrideMonthly) return
    purchaseGiftPlan({
      email: userEmail || form.email,
      openErrorModal: false,
    })
  }

  const onClickPurchaseGiftPlan = useCallback(() => {
    if (clickSubscribeLinkOverrideMonthly) return
    if (userEmail) {
      purchaseGiftPlan({ email: userEmail, openErrorModal: true })
    } else {
      setUserInfoModalIsOpen(true)
    }
  }, [userEmail, purchaseGiftPlan, setUserInfoModalIsOpen, clickSubscribeLinkOverrideMonthly])

  return (
    <>
      {showFixedBanner && (
        <div className={fixedBannerCTA}>
          <ConditionalLinkWrapper href={clickSubscribeLinkOverrideMonthly}>
            <Text variant="overline" isLink={true} onClick={onClickPurchaseGiftPlan}>
              Give {publicationName}
            </Text>
          </ConditionalLinkWrapper>
        </div>
      )}
      <ConditionalLinkWrapper href={clickSubscribeLinkOverrideMonthly}>
        <ConditionalFormWrapper userEmail={userEmail} func={handleSubmit(onSubmit)} variant={variant}>
          <CustomFlex
            direction="column"
            gap={{
              base: '2',
              bp1: '4',
              bp2: '6',
              bp3: '8',
            }}>
            <Text className={bestValue} variant="body2">
              Best Value
            </Text>
            <Text variant="body2">{name}</Text>
            {subtitle && <Text variant="body1">{subtitle}</Text>}
            <Text variant="h5" css={{ mt: '$2' }}>
              {dollarString.format(priceInCents / 100)} for {monthsGifted} months
            </Text>
            {!userEmail && authenticationStatus !== 'loading' && (
              <Controller
                name="email"
                control={control}
                rules={{
                  required: {
                    value: !userEmail,
                    message: 'Your email address is required.',
                  },
                  pattern: {
                    value: validEmail,
                    message: 'Please enter a valid email address.',
                  },
                }}
                render={({ field }) => (
                  <Textfield
                    {...field}
                    css={{ mt: '$1' }}
                    fullWidth={true}
                    floatingLabel="* Gifter's Email Address"
                    reactFormErrors={errors}
                    placeholder="myemail@example.com"
                    inputVariant="body2"
                  />
                )}
              />
            )}
            <Box
              className={buttonContainer({
                hasMarginBottom: !!(planSummary || (benefits && benefits.length)),
              })}>
              <Button
                variant="primary"
                label="Give this gift"
                css={{
                  mx: '$auto',
                }}
                fullWidth={true}
                buttonState={callInProgress && !userInfoModalIsOpen ? 'waiting' : 'default'}
                buttonType={userEmail ? 'button' : 'submit'}
              />
              {errorMsg && !userInfoModalIsOpen && (
                <Text
                  variant="caption"
                  css={{
                    color: '$aleText',
                    backgroundColor: '$ale',
                    p: '$2',
                    borderRadius: '$3',
                    textAlign: 'center',
                    mt: '$2',
                  }}>
                  {errorMsg}
                </Text>
              )}
            </Box>
            {planSummary && (
              <>
                <CustomDivider
                  orientation="horizontal"
                  size="small"
                  css={{ color: '$gs6' }}
                  className={contentContainer}
                />
                <Text variant="body2" className={contentContainer}>
                  {planSummary}
                </Text>
              </>
            )}
            {benefits && !!benefits.length && (
              <>
                <CustomDivider
                  orientation="horizontal"
                  size="small"
                  css={{ color: '$gs6' }}
                  className={contentContainer}
                />
                <WysiwygReader
                  initialValue={benefits}
                  className={contentContainer}
                  css={{
                    '& p': {
                      _before: { content: '"✓ "' },
                    },
                    textWrap: 'pretty',
                    textAlign: 'left',
                  }}
                />
              </>
            )}
          </CustomFlex>
        </ConditionalFormWrapper>
      </ConditionalLinkWrapper>
      <GetUserEmail
        isOpen={userInfoModalIsOpen}
        setIsOpen={setUserInfoModalIsOpen}
        onSubmitCallback={purchaseGiftPlan}
        callInProgress={callInProgress}
      />
      <DisplayErrorToUser errorMsg={errorMsg} isOpen={errorModalIsOpen} setIsOpen={setErrorModalIsOpen} />
    </>
  )
}

const ConditionalLinkWrapper = ({ href, children }) => {
  return href ? <a href={href}>{children}</a> : children
}

const ConditionalFormWrapper = ({
  children,
  userEmail,
  func,
  variant,
}: {
  children: React.ReactNode
  userEmail?: string
  func: any
  variant: 'large' | 'small'
}) => {
  const className = containerStyles({
    clickable: !!userEmail,
    variant,
  })

  return userEmail ? (
    <div onClick={func} className={className}>
      {children}
    </div>
  ) : (
    <form className={className} onSubmit={func}>
      {children}
    </form>
  )
}

const buttonContainer = cva({
  base: {
    textAlign: 'center',
  },
  variants: {
    hasMarginBottom: {
      true: {
        mb: '$4',
        bp1: {
          mb: '$6',
        },
        bp2: {
          mb: '$8',
        },
        bp3: {
          mb: '$10',
        },
      },
      false: {},
    },
  },
})

const fixedBannerCTA = css({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  height: '50px', // linked to index.tsx
  zIndex: '1',
  backgroundColor: '$gs12',
  color: '$gs1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

const containerStyles = cva({
  base: {
    textAlign: 'center',
    position: 'relative',
    borderColor: '$pri_L',
    borderWidth: '$3',
    backgroundColor: '$gs1',
    borderRadius: '$3',
    mx: '$auto',
    px: '30px',
    py: '45px',
  },
  variants: {
    clickable: {
      true: {
        cursor: 'pointer',
      },
      false: {},
    },
    variant: {
      large: {
        mt: '60px',
        mb: '80px',
        transitionDuration: '$normal',
        transitionTimingFunction: 'ease-out',
        _hover: {
          boxShadow: 'lg',
          borderColor: '$pri_D',
          transform: 'translateY(-4px)',
          transitionDuration: '$normal',
          transitionTimingFunction: 'ease-in-out',
        },
        bp1: {
          width: '540px',
          px: '90px',
          py: '70px',
        },
        bp2: {
          width: '640px',
          px: '120px',
          py: '90px',
        },
        bp3: {
          width: '850px',
          px: '180px',
          py: '110px',
        },
      },
      small: {
        mt: '15px',
        p: '$4',
        bp1: {
          p: '$6',
        },
        bp2: {
          p: '$8',
        },
        bp3: {
          p: '$10',
        },
      },
    },
  },
})

const contentContainer = css({
  maxWidth: '260px',
  mx: '$auto',
  bp1: {
    maxWidth: '270px',
  },
  bp2: {
    maxWidth: '340px',
  },
  bp3: {
    maxWidth: '422px',
  },
})

const bestValue = css({
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgColor: '$gs12',
  color: '$gs1',
  borderRadius: '$5',
  py: '$2',
  px: '$3',
})
