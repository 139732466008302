'use client'

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Text } from '@design-system/src/components/Text'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import { FC } from 'react'
import {
  LmTribuneArchiveLinkQueryStringAdditionProps,
  lmTribuneArchiveLinkQueryStringAddition,
} from 'src/utils/client-special-cases/lmtribune'
import { css, cva, cx } from 'styled-system/css'

import { getDropdownChoiceFromMenuItem } from '@shared/util'

import { HeaderMenuUrlItem } from '../../../_utils/typescript-utils'

export const MainHeaderUrlMenuItem: FC<{
  item: HeaderMenuUrlItem
  textClassName?: string
  variant: 'overPrimary' | 'overGs1'
  underlineOnHover: boolean
  wrappableText: boolean
  lmTribuneSpecialCaseData: Omit<LmTribuneArchiveLinkQueryStringAdditionProps, 'url'>
}> = ({ item, textClassName = '', variant, underlineOnHover, wrappableText, lmTribuneSpecialCaseData }) => {
  const pathname = usePathname()

  return (
    <li key={item.id}>
      {item.url === pathname || item.url === `${pathname}/` ? (
        <Text
          variant="body2"
          className={cx(
            css({
              opacity: '[0.75]',
              cursor: 'default',
            }),
            textVariant({
              variant: variant === 'overGs1' ? 'currentOverGs1' : 'currentOverPrimary',
              wrappableText,
            }),
            textClassName,
          )}>
          {item.name}
        </Text>
      ) : getDropdownChoiceFromMenuItem(item) === 'externalUrl' ? (
        <a
          key={item.id}
          href={lmTribuneArchiveLinkQueryStringAddition({
            ...lmTribuneSpecialCaseData,
            url: item.url,
          })}
          target="_blank"
          rel="noreferrer">
          <Text
            variant="body2"
            isLink={underlineOnHover}
            className={cx(textVariant({ variant, wrappableText }), textClassName)}>
            {item.name}
          </Text>
        </a>
      ) : (
        <Link href={item.url} prefetch={false}>
          <Text
            variant="body2"
            isLink={underlineOnHover}
            className={cx(
              textVariant({ variant, wrappableText }),
              css({
                lineHeight: '[1em]',
              }),
              textClassName,
            )}>
            {item.name}
          </Text>
        </Link>
      )}
    </li>
  )
}

const textVariant = cva({
  variants: {
    variant: {
      overGs1: {
        color: '$gs12',
        _hover: {
          color: '$gs11',
        },
      },
      currentOverGs1: {
        color: '$pri',
      },
      overPrimary: {
        color: '$w1',
      },
      currentOverPrimary: {
        color: '$w1',
      },
    },
    wrappableText: {
      true: {
        whiteSpace: 'wrap',
        lineHeight: '1.2em',
      },
      false: {
        whiteSpace: 'nowrap',
        lineHeight: '1em',
      },
    },
  },
})
