'use client'

import { Gutter } from '@design-system/src/components/Gutter'
import { useSession } from 'next-auth/react'
import { BlockPubTagRelatedArticlesCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React from 'react'

import { MAX_NUMBER_OF_ARTICLES_TAG } from '@shared/constants'

import { isAdvertisementVisible } from '../../_utils/ad-utils'
import { adSystemOfSubscription } from '../../_utils/publication-utils'
import { BlockViewProps } from '../../_utils/typescript-utils'
import { GoogleAd } from '../GoogleAd'
import { RelatedArticlesTemplate } from '../RelatedArticlesTemplate'

const collapsableId = 'pub-section-related-articles-horiz-ad'

export const BlockPubTagRelatedArticles: React.FC<
  BlockViewProps<{ ShapeOfCustomPropsDerivedFromPageData: BlockPubTagRelatedArticlesCustomPageData }>
> = props => {
  const {
    articlesByTag,
    googleAdConfigVertical,
    googleAdConfigHorizontal1,
    googleAdConfigArticlesLazyLoad,
    blockId,
    nextToken,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  } = props.blockCustomData
  const { data: session, status } = useSession()
  const adSettings = adSystemOfSubscription({
    status,
    session,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  })
  const verticalAdvertisementIsVisible = isAdvertisementVisible({ googleAdConfig: googleAdConfigVertical })
  const horizontal1AdvertisementIsVisible = isAdvertisementVisible({ googleAdConfig: googleAdConfigHorizontal1 })
  const articlesLazyLoadAdvertisementIsVisible = isAdvertisementVisible({
    googleAdConfig: googleAdConfigArticlesLazyLoad,
  })

  return (
    <div data-sho-block-name="BlockPubTagRelatedArticles" data-sho-block-id={props.blockId}>
      {horizontal1AdvertisementIsVisible && (
        <Gutter css={{ bgColor: '$gs1', overflowX: 'auto' }} id={collapsableId}>
          <GoogleAd {...googleAdConfigHorizontal1} collapsableId={collapsableId} />
        </Gutter>
      )}
      <Gutter>
        <RelatedArticlesTemplate
          articles={articlesByTag}
          googleAdConfig={googleAdConfigVertical}
          blockId={blockId}
          nextToken={nextToken}
          adIsVisible={verticalAdvertisementIsVisible}
          googleAdHorizontalConfig={googleAdConfigArticlesLazyLoad}
          horizontalAdIsVisible={articlesLazyLoadAdvertisementIsVisible}
          type="externalBlocks"
          maxNumberOfArticlesForInitialLoad={MAX_NUMBER_OF_ARTICLES_TAG}
        />
      </Gutter>
    </div>
  )
}
