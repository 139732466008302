'use client'

import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { DotElastic } from '@design-system/src/components/DotElastic'
import { Icon } from '@design-system/src/components/Icon'
import { Text } from '@design-system/src/components/Text'
import { dollarString } from '@design-system/src/utils/string-utils'
import '@radix-ui/react-icons'
import Link from 'next/link'
import { FC, useState } from 'react'
import { formatDateForStoryPreview } from 'src/utils/date-utils'
import { cva } from 'styled-system/css'

import { BlockModal } from '../../../BlockModal'
import { AccountCard } from './AccountCard'

type BlockPubAccountPageUIProps = {
  counponInfo?: string
  // Careful. For Stripe, the mangeSubscriptionUrl sends the user to subscripe page with a query string that allows the 'delete' subscription to show.
  // If we want to do something more sophisticated, we need to change this system.
  manageSubscriptionUrl: string
  onClickProfile: () => void
  userIsSubscribedToPublication?: boolean
  userIsSubscribedToSupportPlan?: boolean
  userEmail?: string
  subscriptionInfo?: string
  customerServiceNumber?: string
  userHasCanceledAtPeriodEnd?: boolean
  onReactivateSubscriptionClick?: () => void
  showReactivateAccountError?: boolean
  reactivateAccountApiCallInProgress?: boolean
  supportPlanLinkOverride?: string
  purchasedGifts?:
    | {
        id: string
        createdAt: string
        couponId: string
        redeemedAt: string | undefined
        amountInCents: number | undefined
        monthsDuration: number | undefined
      }[]
    | undefined
  donationReceipts?: {
    id: string
    createdAt: string
    amountInCents: number
    currency: string
  }[]
}

export const AccountPageUI: FC<BlockPubAccountPageUIProps> = props => {
  const [supportPlanModalOpen, setSupportPlanModalOpen] = useState(true)
  return (
    <>
      {props.userIsSubscribedToPublication && props.userIsSubscribedToSupportPlan && !props.supportPlanLinkOverride && (
        <BlockModal
          openModal={supportPlanModalOpen}
          onCloseModalCallback={setSupportPlanModalOpen}
          title="Manage Subscription">
          <Text variant="body2">
            Please contact customer support to make changes to your subscription
            {props.customerServiceNumber && (
              <a href={`tel:${props.customerServiceNumber}`}>
                <Text variant="body2" isLink={true} css={{ color: '$lin' }}>
                  {props.customerServiceNumber}
                </Text>
              </a>
            )}
            .
          </Text>
        </BlockModal>
      )}
      <CustomGrid
        columns={{ base: '1', bp1: '2' }}
        gap="small"
        css={{
          mt: '$6',
          bp1: {
            mt: '$8',
          },
        }}>
        {/* LEFT CARD */}
        <AccountCard
          title="Your Profile"
          headerCTA={
            <Text
              variant="body2"
              css={{ color: '$pri', cursor: 'pointer' }}
              isLink={true}
              onClick={props.onClickProfile}>
              Manage
            </Text>
          }>
          <Text variant="overline" css={{ mt: '$2', bp2: { mt: '$6' } }}>
            EMAIL ADDRESS
          </Text>
          {props.userEmail && (
            <Text variant="body1" css={{ mt: '$2', color: '$gs11', wordBreak: 'break-word' }}>
              {props.userEmail}
            </Text>
          )}
        </AccountCard>

        {/* RIGHT CARD */}
        {!!(props.userIsSubscribedToPublication || props.showReactivateAccountError) && (
          <div>
            {props.showReactivateAccountError && (
              <Text variant="body1" css={{ color: '$pri', mb: '$2' }}>
                There was an error reactivating your account. Please contact customer support
                {props.customerServiceNumber ? `: ${props.customerServiceNumber}` : ''}.
              </Text>
            )}
            {props.userIsSubscribedToPublication && (
              <AccountCard
                title="Subscription"
                headerCTA={
                  <>
                    {props.userIsSubscribedToSupportPlan ? (
                      <>
                        {props.supportPlanLinkOverride ? (
                          <a href={props.supportPlanLinkOverride}>
                            <Text variant="body2" isLink={true}>
                              Manage
                            </Text>
                          </a>
                        ) : (
                          <Text
                            variant="body2"
                            css={{ color: '$pri', whiteSpace: 'nowrap' }}
                            onClick={() => setSupportPlanModalOpen(true)}>
                            Manage
                          </Text>
                        )}
                      </>
                    ) : props.userHasCanceledAtPeriodEnd && props.onReactivateSubscriptionClick ? (
                      <CustomFlex
                        direction={'column'}
                        align={{
                          base: 'center',
                          bp1: 'end',
                        }}>
                        <Text
                          variant="body2"
                          isLink={props.reactivateAccountApiCallInProgress ? false : true}
                          css={{
                            color: '$pri',
                            whiteSpace: 'nowrap',
                            _hover: {
                              color: '$pri_L',
                            },
                          }}
                          onClick={
                            props.reactivateAccountApiCallInProgress ? undefined : props.onReactivateSubscriptionClick
                          }>
                          Re-subscribe
                        </Text>
                        {props.reactivateAccountApiCallInProgress && (
                          <DotElastic
                            css={{
                              mt: '$1',
                              bp1: {
                                marginRight: '20px',
                              },
                            }}
                          />
                        )}
                      </CustomFlex>
                    ) : (
                      <Link href={props.manageSubscriptionUrl} prefetch={false}>
                        <Text variant="body2" css={{ color: '$pri', whiteSpace: 'nowrap' }}>
                          Manage
                        </Text>
                      </Link>
                    )}
                  </>
                }>
                <Text variant="overline" css={{ mt: '$2', bp2: { mt: '$6' } }}>
                  {props.userHasCanceledAtPeriodEnd ? 'SUBSCRIPTION CANCELLED' : 'CURRENT SUBSCRIPTION'}
                </Text>
                {props.subscriptionInfo && (
                  <Text variant="body1" className={priceText({ strikeThrough: props.userHasCanceledAtPeriodEnd })}>
                    {props.subscriptionInfo}
                  </Text>
                )}
                {props.counponInfo && (
                  <Text variant="body2" css={{ color: '$pri' }}>
                    {props.counponInfo}
                  </Text>
                )}
              </AccountCard>
            )}
          </div>
        )}

        {!!props.purchasedGifts?.length && (
          <AccountCard title="Gift Coupons">
            <CustomFlex direction={'column'} gap="4" css={{ mt: '$6', maxHeight: '250px', overflowY: 'auto' }}>
              {props.purchasedGifts
                .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
                .map(gift => (
                  <CustomFlex key={gift.id} direction={'row'} gap="2">
                    <span>
                      <Icon
                        reactIcon={
                          <svg height="24px" viewBox="0 -960 960 960" width="24px">
                            <path d="M160-280v80h640v-80H160Zm0-440h88q-5-9-6.5-19t-1.5-21q0-50 35-85t85-35q30 0 55.5 15.5T460-826l20 26 20-26q18-24 44-39t56-15q50 0 85 35t35 85q0 11-1.5 21t-6.5 19h88q33 0 56.5 23.5T880-640v440q0 33-23.5 56.5T800-120H160q-33 0-56.5-23.5T80-200v-440q0-33 23.5-56.5T160-720Zm0 320h640v-240H596l84 114-64 46-136-184-136 184-64-46 82-114H160v240Zm200-320q17 0 28.5-11.5T400-760q0-17-11.5-28.5T360-800q-17 0-28.5 11.5T320-760q0 17 11.5 28.5T360-720Zm240 0q17 0 28.5-11.5T640-760q0-17-11.5-28.5T600-800q-17 0-28.5 11.5T560-760q0 17 11.5 28.5T600-720Z" />
                          </svg>
                        }
                        css={{
                          fill: '$pri',
                        }}
                      />
                    </span>
                    <CustomFlex direction={'column'} gap="1">
                      <Text variant="body2">{gift.couponId}</Text>

                      {gift.amountInCents && gift.monthsDuration && (
                        <Text variant="body2">
                          {dollarString.format(gift.amountInCents / 100)} for {gift.monthsDuration} months
                        </Text>
                      )}
                      <Text variant="body2">
                        purchased {formatDateForStoryPreview({ date: gift.createdAt, isMonthNameAbbreviated: true })}
                      </Text>
                      {gift.redeemedAt ? (
                        <Text variant="body2">
                          redeemed {formatDateForStoryPreview({ date: gift.redeemedAt, isMonthNameAbbreviated: true })}
                        </Text>
                      ) : (
                        <Text variant="body2">redeemable: not yet claimed</Text>
                      )}
                    </CustomFlex>
                  </CustomFlex>
                ))}
            </CustomFlex>
          </AccountCard>
        )}
        {!!props.donationReceipts?.length && (
          <AccountCard title="Donation Receipts">
            <CustomFlex direction={'column'} gap="4" css={{ mt: '$6', maxHeight: '250px', overflowY: 'auto' }}>
              {props.donationReceipts.map(receipt => (
                <CustomFlex key={receipt.id} direction={'row'} gap="2">
                  <span>
                    <Icon
                      reactIcon={
                        <svg height="24px" viewBox="0 -960 960 960" width="24px">
                          <path d="M640-440 474-602q-31-30-52.5-66.5T400-748q0-55 38.5-93.5T532-880q32 0 60 13.5t48 36.5q20-23 48-36.5t60-13.5q55 0 93.5 38.5T880-748q0 43-21 79.5T807-602L640-440Zm0-112 109-107q19-19 35-40.5t16-48.5q0-22-15-37t-37-15q-14 0-26.5 5.5T700-778l-60 72-60-72q-9-11-21.5-16.5T532-800q-22 0-37 15t-15 37q0 27 16 48.5t35 40.5l109 107ZM280-220l278 76 238-74q-5-9-14.5-15.5T760-240H558q-27 0-43-2t-33-8l-93-31 22-78 81 27q17 5 40 8t68 4q0-11-6.5-21T578-354l-234-86h-64v220ZM40-80v-440h304q7 0 14 1.5t13 3.5l235 87q33 12 53.5 42t20.5 66h80q50 0 85 33t35 87v40L560-60l-280-78v58H40Zm80-80h80v-280h-80v280Zm520-546Z" />
                        </svg>
                      }
                      css={{
                        fill: '$pri',
                      }}
                    />
                  </span>
                  <Text variant="body2">
                    {receipt.amountInCents / 100}&nbsp;{receipt.currency.toUpperCase()}
                  </Text>
                  —
                  <Text variant="body2" css={{ color: '$gs11' }}>
                    {formatDateForStoryPreview({ date: receipt.createdAt, isMonthNameAbbreviated: true })}
                  </Text>
                </CustomFlex>
              ))}
            </CustomFlex>
          </AccountCard>
        )}
      </CustomGrid>
    </>
  )
}

const priceText = cva({
  base: {
    mt: '$2',
    color: '$gs11',
  },
  variants: {
    strikeThrough: {
      true: {
        textDecoration: 'line-through',
      },
      false: {},
    },
  },
  defaultVariants: {
    strikeThrough: false,
  },
})
