import { Box } from '@design-system/src/components/Box'
import { Button } from '@design-system/src/components/Button'
import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import { dollarString } from '@design-system/src/utils/string-utils'
import { capitalizeFirstLetter } from '@next-app/src/utils/string-utils'
import { FC, useState } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { css, cva, cx } from 'styled-system/css'
import type { SystemStyleObject } from 'styled-system/types'

import { ShippingAddress } from '@models/types'
import { WysiwygElement } from '@models/wysiwyg-types'

import { EmailAndAddressFormInput, EmailAndAddressModal } from './EmailAndAddressModal'

export type StripePlanFrequencyLabel = 'Year' | 'Week' | 'Month'

type PlanCardProps = {
  planSummary?: string
  benefits?: WysiwygElement[]
  priceSubtitle?: string
  isTopChoice: boolean
  name?: string
  subtitle?: string
  planPrice: number
  planStruckOutPrice?: number
  planFrequencyLabel: StripePlanFrequencyLabel
  userIsSubscribedToPlanAndPrice: boolean
  isUserUnauthenticated: boolean
  css?: SystemStyleObject
  onSubmitUserInfo: SubmitHandler<EmailAndAddressFormInput>
  onSubscribeToPlan: (props: {
    email?: string
    shippingAddress?: NonNullable<Omit<ShippingAddress, '__typename'>>
  }) => void
  errorMsg?: string
  subscriptionInProgress: boolean
  planRequiresShipping: boolean
  totalNumberOfPlans: 1 | 2 | 3
  secondaryFrequencyLabel?: 'week' | 'month'
  yearlySavingsInCents?: number
  clickSubscribeLinkOverride?: string
  promoText?: string
  planCardType: 'subscription' | 'donation'
}

export const PlanCard: FC<PlanCardProps> = ({
  benefits,
  css: cssProp = {},
  priceSubtitle,
  isTopChoice,
  name,
  totalNumberOfPlans,
  subtitle,
  planSummary,
  userIsSubscribedToPlanAndPrice,
  onSubmitUserInfo,
  onSubscribeToPlan,
  errorMsg = '',
  subscriptionInProgress,
  planPrice,
  planStruckOutPrice,
  planFrequencyLabel,
  isUserUnauthenticated,
  planRequiresShipping,
  secondaryFrequencyLabel,
  yearlySavingsInCents,
  clickSubscribeLinkOverride,
  promoText,
  planCardType,
}) => {
  const [userInfoModalIsOpen, setUserInfoModalIsOpen] = useState(false)

  const yearlyPrice = planFrequencyLabel === 'Year' ? planPrice : null
  const monthlyPrice =
    planFrequencyLabel === 'Month' ? planPrice : planFrequencyLabel === 'Year' ? planPrice / 12 : null
  const weeklyPrice = planFrequencyLabel === 'Week' ? planPrice : planFrequencyLabel === 'Year' ? planPrice / 52 : null
  const isYearlyCard = planFrequencyLabel === 'Year'
  const priceToDisplay =
    planFrequencyLabel === 'Month'
      ? monthlyPrice
      : planFrequencyLabel === 'Week'
        ? weeklyPrice
        : secondaryFrequencyLabel === 'month'
          ? monthlyPrice
          : weeklyPrice
  const displayPriceFrequency = secondaryFrequencyLabel === 'month' ? 'Month' : 'Week'

  const struckOutPriceToDisplay = !planStruckOutPrice
    ? null
    : planFrequencyLabel === 'Year'
      ? planStruckOutPrice / 12
      : planStruckOutPrice

  return (
    <>
      <ConditionalLinkWrapper href={clickSubscribeLinkOverride}>
        <div
          className={cx(
            cardContainer({
              totalNumberOfPlans,
              isTopChoice,
              userIsSubscribedToPlanAndPrice,
            }),
            css(cssProp),
          )}
          onClick={() => {
            if (clickSubscribeLinkOverride) return
            if (isUserUnauthenticated || planRequiresShipping) {
              setUserInfoModalIsOpen(true)
            } else onSubscribeToPlan({})
          }}>
          <div className={headerContainer({ isTopChoice })}>
            <Text variant="h6" css={{ color: '$gs1' }}>
              {isTopChoice ? <>Reader&apos;s&nbsp;Choice</> : <>&nbsp;</>}
            </Text>
          </div>
          <div className={bodyContainer}>
            {name && (
              <>
                {isTopChoice ? (
                  <h1>
                    <Text variant="h3">{name}</Text>
                  </h1>
                ) : (
                  <Text variant="h3">{name}</Text>
                )}
              </>
            )}
            {subtitle && <Text variant="body1">{subtitle}</Text>}
            <div>
              <Text
                variant="h4"
                css={{
                  mx: '[-10px]',
                }}>
                {struckOutPriceToDisplay && (
                  <span className={struckoutPrice}>{dollarString.format(struckOutPriceToDisplay / 100)}</span>
                )}
                {!!priceToDisplay && formatPlan(priceToDisplay, displayPriceFrequency)}
              </Text>
              {isYearlyCard && yearlyPrice && !priceSubtitle && (
                <Text variant="body2" css={{ mt: '$2' }}>
                  ({dollarString.format(yearlyPrice / 100)} charged annually)
                </Text>
              )}
              {yearlySavingsInCents && (
                <Text variant="subtitle2" css={{ color: '$gs11', mt: '$2' }}>
                  Save {dollarString.format(yearlySavingsInCents / 100)} per year when you pay annually
                </Text>
              )}
              {priceSubtitle && (
                <Text variant="body2" css={{ mt: '$2' }}>
                  {priceSubtitle}
                </Text>
              )}
            </div>
            {planSummary && (
              <>
                <CustomDivider orientation="horizontal" size="small" css={{ color: '$gs6' }} />
                <Text variant="body2">{planSummary}</Text>
              </>
            )}
            {benefits && !!benefits.length && (
              <>
                <CustomDivider orientation="horizontal" size="small" css={{ color: '$gs6' }} />
                <WysiwygReader
                  initialValue={benefits}
                  css={{
                    '& p': {
                      _before: { content: '"✓ "' },
                    },
                    textWrap: 'pretty',
                  }}
                />
              </>
            )}
          </div>
          <Box css={{ mx: '$5', mb: '$5' }}>
            {promoText && (
              <Text variant="caption" css={{ color: '$gs11', mb: '$2' }}>
                {promoText}
              </Text>
            )}
            <Box>
              {userIsSubscribedToPlanAndPrice ? (
                <Text variant="h6">Current Plan</Text>
              ) : (
                <Button
                  variant="primary"
                  label={
                    promoText
                      ? `${planCardType === 'donation' ? 'Donate' : 'Subscribe'} with Sale *`
                      : `${planCardType === 'donation' ? 'Donate' : 'Subscribe'} Now`
                  }
                  fullWidth={true}
                  buttonState={subscriptionInProgress ? 'waiting' : 'default'}
                  onClick={() => {}}
                />
              )}
            </Box>
            {errorMsg && (
              <CustomFlex
                justify="center"
                align="center"
                css={{ borderRadius: '$3', backgroundColor: '$ale', mt: '$6' }}>
                <Text variant="caption" css={{ color: '$aleText' }}>
                  {errorMsg}
                </Text>
              </CustomFlex>
            )}
          </Box>
        </div>
      </ConditionalLinkWrapper>
      <EmailAndAddressModal
        userInfoModalIsOpen={userInfoModalIsOpen}
        setUserInfoModalIsOpen={setUserInfoModalIsOpen}
        onSubmitUserInfo={onSubmitUserInfo}
        isUserUnauthenticated={isUserUnauthenticated}
        planRequiresShipping={planRequiresShipping}
        errorMsg={errorMsg}
        subscriptionInProgress={subscriptionInProgress}
      />
    </>
  )
}

const struckoutPrice = css({
  marginRight: '$1',
  color: '$gs11',
  textDecoration: 'line-through',
})

const cardContainer = cva({
  base: {
    height: '100%',
    overflow: 'hidden',
    borderWidth: '$1',
    borderStyle: 'solid',
    borderRadius: '$4',
    width: '[80dvw]',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    transitionProperty: 'box-shadow, transform, border',
    transitionDuration: '$normal',
    _hover: {
      transitionProperty: 'box-shadow, transform, border',
      transitionDuration: '$normal',
    },
    bp1: {
      width: '[500px]',
    },
    bp3: {
      maxWidth: '[500px]',
    },
  },
  variants: {
    totalNumberOfPlans: {
      3: {
        bp3: {
          width: 'auto',
        },
      },
      2: {},
      1: {},
    },
    isTopChoice: {
      true: {},
      false: {},
    },
    userIsSubscribedToPlanAndPrice: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      isTopChoice: true,
      userIsSubscribedToPlanAndPrice: false,
      css: {
        boxShadow: 'md',
        borderColor: '$pri_L',
        _hover: {
          boxShadow: 'lg',
          borderColor: '$pri_D',
          transform: 'translateY(-4px)',
        },
      },
    },
    {
      isTopChoice: false,
      userIsSubscribedToPlanAndPrice: false,
      css: {
        borderColor: '$gs6',
        _hover: {
          boxShadow: 'md',
          borderColor: '$gs7',
          // transform: 'translateY(-4px)',
        },
      },
    },
  ],
})

const headerContainer = cva({
  base: {
    py: '$1',
  },
  variants: {
    isTopChoice: {
      true: {
        backgroundColor: '$pri',
        color: '$priText',
      },
      false: {
        backgroundColor: '$gs3',
        color: '$gs12',
      },
    },
  },
  defaultVariants: {
    isTopChoice: false,
  },
})

const bodyContainer = css({
  padding: '$5',
  display: 'flex',
  flexDirection: 'column',
  gap: '$5',
  flexGrow: 1,
})

// TODO: place in shared
const formatPlan = (priceInCents: number, timeFrame: string): string => {
  const priceInDollars = priceInCents / 100
  return `${dollarString.format(priceInDollars)} / ${capitalizeFirstLetter(timeFrame)}`
}

const ConditionalLinkWrapper = ({ href, children }) => {
  return href ? <a href={href}>{children}</a> : children
}
