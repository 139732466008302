'use client'

import { useEffect, useRef, useState } from 'react'

import { GoogleAd } from '../GoogleAd'

type AdSize = {
  width: number
  height: number
}

export const DynamicAd = ({ googleAdHorizontalConfig, horizontalCollapsableId }) => {
  const [isVisible, setIsVisible] = useState(false)
  const [adSize, setAdSize] = useState<AdSize | null>(null)
  const adRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setIsVisible(true)
            setAdSize(entry.boundingClientRect)
          } else {
            setIsVisible(false)
          }
        })
      },
      { threshold: 0.1 }, // Trigger when 10% of the ad is visible
    )

    if (adRef.current) {
      observer.observe(adRef.current)
    }

    return () => {
      if (adRef.current) {
        observer.unobserve(adRef.current)
      }
    }
  }, [])

  // console.log(googleAdHorizontalConfig)

  if (googleAdHorizontalConfig.adSystem === 'custom') {
    // For custom ads, we don't need to remove the ad when it's not visible. Only for Google Ads which can't handle the same Ad Unit multiple times.
    return (
      <div style={{ minHeight: '100px', margin: '0 auto' }}>
        <GoogleAd {...googleAdHorizontalConfig} collapsableId={horizontalCollapsableId} />
      </div>
    )
  }

  return (
    <div ref={adRef} style={{ minHeight: '100px', margin: '0 auto' }}>
      {isVisible ? (
        <GoogleAd
          {...googleAdHorizontalConfig}
          collapsableId={horizontalCollapsableId}
          css={{
            justifyContent: 'flex-start',
          }}
        />
      ) : adSize ? (
        // We want to show a placeholder for the ad size so that the layout doesn't shift when the ad is loaded
        <div style={{ width: adSize.width, height: adSize.height }} />
      ) : null}
    </div>
  )
}
