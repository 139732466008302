import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Text } from '@design-system/src/components/Text'
import type { BlockPubArticleBodyCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { css } from 'styled-system/css'

import { ArticlePreviewVerticalSmall } from '../../ArticlePreviewVerticalSmall'

export const RelatedArticles = ({
  relatedArticles,
}: {
  relatedArticles: BlockPubArticleBodyCustomPageData['relatedArticles']
}) => (
  <CustomFlex
    direction="column"
    css={{
      width: '$full',
      display: 'none',
      bp3: {
        display: 'flex',
        height: 'fit',
      },
    }}>
    <CustomDivider
      orientation="horizontal"
      size="medium"
      css={{ color: '$gs7', mb: '$4', display: 'none', bp3: { display: 'block' } }}
    />
    <Text variant="h5" css={{ mb: '$5' }}>
      Related
    </Text>
    <CustomFlex direction="column" css={secondaryArticlesContainer}>
      {relatedArticles?.map(el => (
        <ArticlePreviewVerticalSmall {...el} key={el.articleHref} hideImage={true} makeTitleTextSmall={true} />
      ))}
    </CustomFlex>
  </CustomFlex>
)

const secondaryArticlesContainer = css.raw({
  '& > *:not(:last-child)': {
    pb: '$4',
    mb: '$4',
    borderBottomWidth: '$1',
    borderStyle: 'solid',
    borderColor: '$gs6',
  },
  display: 'none',
  bp3: {
    display: 'flex',
  },
})
