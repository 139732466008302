'use client'

import { Box } from '@design-system/src/components/Box'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import { useSession } from 'next-auth/react'
import type { BlockPubArticleBodyCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { FC } from 'react'

import { WysiwygElement } from '@models/wysiwyg-types'

import { EmailCapture } from '../../EmailCapture'

export const ArticleText: FC<{
  articleMidpoint: number
  articleContent: WysiwygElement[]
  articleType: BlockPubArticleBodyCustomPageData['articleType']
  emailCaptureConfig: BlockPubArticleBodyCustomPageData['emailCaptureConfig']
  newsletterId: BlockPubArticleBodyCustomPageData['emailCaptureConfig']['newsletterId']
}> = ({ articleContent, articleMidpoint, articleType, emailCaptureConfig, newsletterId }) => {
  const { data: session, status } = useSession()

  // We need to check if the user is subscribed to all newsletters here in addition to the EmailCapture for UI reasons. If we don't, there is no way to know whether or not we need to seperate the WYSIWYG content from the email capture form, which would at minimum cause a gap in the content.
  const userNewsletterSubscriptionIds = session?.user.newsletterSubscriptions?.map(n => n.newsletterId) ?? []
  const isUserSubscribedToAllNewsletters = emailCaptureConfig?.availableNewsletters
    ? emailCaptureConfig?.availableNewsletters?.every(newsletter =>
        userNewsletterSubscriptionIds.includes(newsletter.id),
      )
    : true

  // We don't want to inject the email capture form right after a heading
  let articleInjectionPoint =
    articleContent[articleMidpoint - 1]?.type === 'h3' || articleContent[articleMidpoint - 1]?.type === 'h5'
      ? articleMidpoint - 1
      : articleMidpoint

  return articleType === 'default' && articleContent ? (
    newsletterId && !isUserSubscribedToAllNewsletters ? (
      <CustomFlex direction="column" gap={'10'}>
        <WysiwygReader initialValue={articleContent.slice(0, articleInjectionPoint)} css={wysiwygStylesOverride} />
        <Box css={{ '@media print': { display: 'none' } }}>
          <EmailCapture
            {...emailCaptureConfig}
            variation="light"
            returnDivIfEmpty={false}
            css={{ '@media print': { display: 'none' } }}
          />
        </Box>
        <WysiwygReader initialValue={articleContent.slice(articleInjectionPoint)} css={wysiwygStylesOverride} />
      </CustomFlex>
    ) : (
      <WysiwygReader initialValue={articleContent} />
    )
  ) : articleContent ? (
    <WysiwygReader initialValue={articleContent} />
  ) : null
}

const wysiwygStylesOverride = {
  '& p[data-wisiwig-body1]': {
    color: '$b2',
  },
} as const
