'use client'

import { Gutter } from '@design-system/src/components/Gutter'
import { useSession } from 'next-auth/react'
import { BlockPubAuthorRelatedArticlesCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React from 'react'

import { MAX_NUMBER_OF_ARTICLES_AUTHOR } from '@shared/constants'

import { isAdvertisementVisible } from '../../_utils/ad-utils'
import { adSystemOfSubscription } from '../../_utils/publication-utils'
import { BlockViewProps } from '../../_utils/typescript-utils'
import { RelatedArticlesTemplate } from '../RelatedArticlesTemplate'

export const BlockPubAuthorRelatedArticles: React.FC<
  BlockViewProps<{ ShapeOfCustomPropsDerivedFromPageData: BlockPubAuthorRelatedArticlesCustomPageData }>
> = props => {
  const { articles, googleAdConfig, blockId, nextToken, stripePlans, stripeSupportPlans, connectedSubscriptionSystem } =
    props.blockCustomData
  const { data: session, status } = useSession()
  const adSettings = adSystemOfSubscription({
    status,
    session,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  })
  const advertisementIsVisible = isAdvertisementVisible({ googleAdConfig, adSettings })
  return (
    <Gutter data-sho-block-name="BlockPubAuthorRelatedArticles" data-sho-block-id={props.blockId}>
      <div
        id="blockPubAuthorRelatedArticles" // Do not remove. Used by BlockPubAuthorOverview. It it used by Link to anchor
      >
        <RelatedArticlesTemplate
          articles={articles}
          googleAdConfig={googleAdConfig}
          blockId={blockId}
          nextToken={nextToken}
          adIsVisible={advertisementIsVisible}
          type="externalBlocks"
          maxNumberOfArticlesForInitialLoad={MAX_NUMBER_OF_ARTICLES_AUTHOR}
        />
      </div>
    </Gutter>
  )
}
