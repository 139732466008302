import { abbreviateMonthName, monthNames } from '@design-system/src/utils/date-utils'

import { fromUnixToUTC } from '@shared/util'

export const formatDateForStoryPreview = ({
  date,
  isMonthNameAbbreviated,
  hideYearIfCurrentYear = true,
}: {
  date: string | number
  isMonthNameAbbreviated: boolean
  hideYearIfCurrentYear?: boolean
}): string => {
  const d = new Date(date)
  const day = d.getUTCDate()
  const month = d.getUTCMonth() //months from 1-12
  const year = d.getUTCFullYear()
  const isCurrentYear = year === new Date().getUTCFullYear()
  const monthName = monthNames[month]
  const monthNameToUse = isMonthNameAbbreviated ? abbreviateMonthName(monthName) : monthName
  const yearToUse = hideYearIfCurrentYear ? (isCurrentYear ? '' : `, ${year}`) : `, ${year}`

  return `${monthNameToUse} ${day}${yearToUse}`
}

export const formatDateForEventPreview = ({
  beginDate,
  endDate,
}: {
  beginDate: string | number
  endDate: string | number
}) => {
  // Convert timestamps to Date objects if they're numbers
  const begin = new Date(typeof beginDate === 'number' ? fromUnixToUTC(beginDate) : beginDate)
  const end = new Date(typeof endDate === 'number' ? fromUnixToUTC(endDate) : endDate)

  // Check if dates are on the same calendar day
  const isSameDay =
    begin.getUTCDate() === end.getUTCDate() &&
    begin.getUTCMonth() === end.getUTCMonth() &&
    begin.getUTCFullYear() === end.getUTCFullYear()

  if (isSameDay) {
    // Format: Month Day, Year (if not current year) - Start Time to End Time
    const dateStr = formatDateForStoryPreview({
      date: begin.getTime(),
      isMonthNameAbbreviated: false,
      hideYearIfCurrentYear: true,
    })

    const startTime = begin.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })
    const endTime = end.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    })

    return `${dateStr} - ${startTime} to ${endTime}`
  } else {
    // Format: Start Date to End Date (without times)
    const startDate = formatDateForStoryPreview({
      date: begin.getTime(),
      isMonthNameAbbreviated: false,
      hideYearIfCurrentYear: true,
    })
    const endDate = formatDateForStoryPreview({
      date: end.getTime(),
      isMonthNameAbbreviated: false,
      hideYearIfCurrentYear: true,
    })

    return `${startDate} to ${endDate}`
  }
}
