'use client'

import { Gutter } from '@design-system/src/components/Gutter'
import { useSession } from 'next-auth/react'
import { BlockPubSectionRelatedArticlesCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React from 'react'

import { MAX_NUMBER_OF_ARTICLES_SECTION } from '@shared/constants'

import { isAdvertisementVisible } from '../../_utils/ad-utils'
import { adSystemOfSubscription } from '../../_utils/publication-utils'
import { BlockViewProps } from '../../_utils/typescript-utils'
import { GoogleAd } from '../GoogleAd'
import { RelatedArticlesTemplate } from '../RelatedArticlesTemplate'

const collapsableId = 'pub-section-related-articles-horiz-ad'

export const BlockPubSectionRelatedArticles: React.FC<
  BlockViewProps<{ ShapeOfCustomPropsDerivedFromPageData: BlockPubSectionRelatedArticlesCustomPageData }>
> = props => {
  const {
    topArticlesBySection,
    googleAdConfigVertical,
    googleAdConfigHorizontal1,
    googleAdConfigArticlesLazyLoad,
    nextToken,
    blockId,
  } = props.blockCustomData
  const { data: session, status } = useSession()
  const adSettings = adSystemOfSubscription({
    status,
    session,
    stripePlans: props.blockCustomData.stripePlans,
    stripeSupportPlans: props.blockCustomData.stripeSupportPlans,
    connectedSubscriptionSystem: props.blockCustomData.connectedSubscriptionSystem,
  })
  const verticalAdvertisementIsVisible = isAdvertisementVisible({ googleAdConfig: googleAdConfigVertical, adSettings })
  const horizontal1AdvertisementIsVisible = isAdvertisementVisible({
    googleAdConfig: googleAdConfigHorizontal1,
    adSettings,
  })
  const articlesLazyLoadAdvertisementIsVisible = isAdvertisementVisible({
    googleAdConfig: googleAdConfigArticlesLazyLoad,
    adSettings,
  })

  return (
    <div data-sho-block-name="BlockPubSectionRelatedArticles" data-sho-block-id={props.blockId}>
      {horizontal1AdvertisementIsVisible && (
        <Gutter css={{ bgColor: '$gs1', overflowX: 'auto' }} id={collapsableId}>
          <GoogleAd {...googleAdConfigHorizontal1} collapsableId={collapsableId} />
        </Gutter>
      )}
      <Gutter>
        <RelatedArticlesTemplate
          articles={topArticlesBySection}
          blockId={blockId}
          nextToken={nextToken}
          googleAdConfig={googleAdConfigVertical}
          adIsVisible={verticalAdvertisementIsVisible}
          googleAdHorizontalConfig={googleAdConfigArticlesLazyLoad}
          horizontalAdIsVisible={articlesLazyLoadAdvertisementIsVisible}
          type="externalBlocks"
          maxNumberOfArticlesForInitialLoad={MAX_NUMBER_OF_ARTICLES_SECTION}
        />
      </Gutter>
    </div>
  )
}
