import { capitalizeFirstLetter } from './string-utils'

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

export const getDayMonthYearFromDate = (
  date: Date = new Date(),
): {
  day: number
  dayName: string
  month: number
  monthName: string
  year: number
} => {
  const month = date.getMonth()
  const monthName = monthNames[date.getMonth()]
  const day = date.getDate()
  const dayName = dayNames[date.getDay()]
  const year = date.getFullYear()
  return { day, dayName, month, monthName, year }
}

export const abbreviateMonthName = (monthName: string): string => {
  switch (monthName.toLowerCase()) {
    case 'may':
      return 'May'
    case 'june':
      return 'June'
    case 'july':
      return 'July'
    default:
      return `${capitalizeFirstLetter(monthName.slice(0, 3))}.`
  }
}

export const isStoryArchived = (date?: string | number | null): boolean => {
  if (!date || date === '0' || date === 0) return false
  try {
    // if date is of type number, then it's a Unix timestamp
    // otherwise, it is a UTC string
    const archiveDate = typeof date === 'number' ? new Date(date * 1000) : new Date(date)
    const currentDate = new Date() // This is created
    return archiveDate <= currentDate
  } catch (error) {
    console.log('error in isStoryExpired: error, date, timeZone', error, date)
    return true // if there is an error, just return false
  }
}

export const isReleaseDateInFuture = (date: string | number): boolean => {
  try {
    // if date is of type number, then it's a Unix timestamp
    // otherwise, it is a UTC string
    const releaseDate = typeof date === 'number' ? new Date(date * 1000).toUTCString() : new Date(date)
    const currentDate = new Date() // This is created
    return releaseDate >= currentDate
  } catch (error) {
    console.log('error in isReleaseDateInFuture: error, date, timeZone', error, date)
    return true // if there is an error, just return false
  }
}

export const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export function getMonthDay(currentDate: Date) {
  const date = new Date(currentDate)
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1)
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0)
  return (lastDay.getTime() - firstDay.getTime()) / (24 * 3600000) + 1
}

export function getChartLabelByDate(label: string) {
  const xLabel: string[] = []
  switch (label) {
    case 'Day':
      for (let i = 12; i < 24; i++) {
        xLabel.push(i - 12 > 9 ? `${i - 12}` : i === 12 ? `${i}` : `0${i - 12}`)
      }
      return [...xLabel, ...xLabel]
    case 'Week':
      return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    case 'Month':
      const monthDays = getMonthDay(new Date())
      for (let i = 1; i <= monthDays; i++) {
        xLabel.push(i > 10 ? `${i}` : `0${i}`)
      }
      return xLabel
  }
  return []
}

export function getSunday(date: Date) {
  const day = date.getDay()
  const diff = date.getDate() - day + (day == 0 ? -6 : 1)
  return new Date(date.setDate(diff) - 24 * 3600000)
}

export function getSaturday(date: Date) {
  const day = date.getDay()
  const diff = date.getDate() - day + 7
  return new Date(date.setDate(diff) - 24 * 3600000)
}

export function getPeriodString(period: string) {
  const date = new Date()
  switch (period) {
    case 'Day':
      return `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
    case 'Week':
      const sunday = getSunday(date)
      const saturday = getSaturday(date)
      if (sunday.getFullYear() === saturday.getFullYear())
        if (sunday.getMonth() === saturday.getMonth())
          return `${monthNames[saturday.getMonth()]} ${sunday.getDate()} - ${saturday.getDate()}, ${saturday.getFullYear()}`
        else
          return `${monthNames[sunday.getMonth()]} ${sunday.getDate()} - ${
            monthNames[saturday.getMonth()]
          } ${saturday.getDate()}, ${saturday.getFullYear()}`
      else
        return `${monthNames[sunday.getMonth()]} ${sunday.getDate()}, ${sunday.getFullYear()} - ${
          monthNames[saturday.getMonth()]
        } ${saturday.getDate()}, ${saturday.getFullYear()}`
    case 'Month':
      return `${monthNames[date.getMonth()]}, ${date.getFullYear()}`
    case 'Year':
      return `${date.getFullYear()}`
  }
  return 'All'
}

export function isOneMonthOverFromNow(dateString: string): boolean {
  // date type is awsdatestring. YYYY-MM-DDThh:mm:ss.sssZ.
  const date = new Date(dateString)
  const now = new Date()
  const timeDiff = now.getTime() - date.getTime()
  const oneMonthDiff = 1000 * 3600 * 24 * 30
  return timeDiff > oneMonthDiff
}
