'use client'

import { Accordion } from '@design-system/src/components/Accordion'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { Gutter } from '@design-system/src/components/Gutter'
import { ShoImage } from '@design-system/src/components/ShoImage'
import { Text } from '@design-system/src/components/Text'
import { WysiwygReader } from '@design-system/src/components/WysiwygReader'
import giftJson from '@next-public-site/public/_static/gift-animation.json'
import { useSession } from 'next-auth/react'
import { BlockPubGiftCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import dynamic from 'next/dynamic'
import Link from 'next/link'
import React from 'react'
import { css, cva } from 'styled-system/css'

import { WysiwygElement } from '@models/wysiwyg-types'

import { BlockViewProps } from '../../../_utils/typescript-utils'
import { GiftPlan } from './GiftPlan'
import { StarredGiftPlan } from './StarredGiftPlan'
import { SuccessOrFailModal } from './SuccessOrFailModal'

const LottiePlayer = dynamic(() => import('lottie-react'), { ssr: false })

export type GiftPlanUI = {
  planSummary?: string
  benefits?: WysiwygElement[]
  name?: string
  subtitle?: string
  planPrice: number
  monthsGifted: number
}

export const BlockPubGift: React.FC<
  BlockViewProps<{ ShapeOfCustomPropsDerivedFromPageData: BlockPubGiftCustomPageData }>
> = props => {
  const { data: session, status } = useSession()

  const {
    headerLogoSrc,
    headerLogoWidth,
    headerLogoHeight,
    showHeaderGIF,
    headerTitle,
    headerSubtitle,
    disclaimer,
    plansWithGiftsPrices,
    starredGiftPlan,
    stripePublishableKey,
    customerServiceNumber,
    customerServiceNumberURI,
    accordion,
    siteType,
  } = props.blockCustomData

  const logoVariant =
    headerLogoHeight === headerLogoWidth
      ? 'square'
      : (headerLogoWidth || 0) > (headerLogoHeight || 0)
        ? 'landscape'
        : 'portrait'

  const userEmail = session?.user?.email

  return (
    <div data-sho-block-name="BlockPubGift" data-sho-block-id={props.blockId}>
      <Gutter className={gutterStyled({ fixedBannerIsVisible: !!starredGiftPlan })}>
        {!plansWithGiftsPrices?.length && !starredGiftPlan?.giftProductId ? (
          <CustomFlex direction={'column'} gap={{ base: '3', bp1: '3', bp2: '4', bp3: '5' }} align={'center'}>
            <h1>
              <Text variant="h3">Gift Page</Text>
            </h1>
            <div>
              <LottiePlayer animationData={giftJson} autoplay loop className={giftGifStyle} />
            </div>
            <Text variant="body1">
              Gift options aren’t available at the moment, but stay tuned – exciting options may be coming your way
              soon!
            </Text>
          </CustomFlex>
        ) : (
          <>
            <CustomFlex direction={'column'} gap="5" css={topSection} align={'center'}>
              {headerLogoSrc && headerLogoWidth && headerLogoHeight && (
                <Link href="/" prefetch={false}>
                  <ShoImage
                    src={headerLogoSrc}
                    width={headerLogoWidth}
                    height={headerLogoHeight}
                    alt="header logo"
                    className={logoStyle({
                      variant: logoVariant,
                    })}
                    sizes="50vw"
                  />
                </Link>
              )}
              {showHeaderGIF && (
                <div>
                  <LottiePlayer animationData={giftJson} autoplay loop className={giftGifStyle} />
                </div>
              )}
              {headerTitle && (
                <h1>
                  <Text variant="h4">{headerTitle}</Text>
                </h1>
              )}
              {headerSubtitle && <Text variant="body1">{headerSubtitle}</Text>}
            </CustomFlex>
            {starredGiftPlan && (
              <>
                <StarredGiftPlan
                  {...starredGiftPlan}
                  stripePublishableKey={stripePublishableKey}
                  userEmail={userEmail}
                  customerServiceNumber={customerServiceNumber}
                  showFixedBanner={true}
                  authenticationStatus={status}
                  variant="large"
                  siteType={siteType}
                />
                <Text variant="h5" css={{ textAlign: 'center' }} oll={false}>
                  Additional Gift Options:
                </Text>
              </>
            )}
            <CustomGrid
              className={planGiftCardsContainer({
                columns: plansWithGiftsPrices?.length === 1 ? 1 : plansWithGiftsPrices?.length === 2 ? 2 : 3,
              })}
              columns={{
                base: '1',
                bp3: plansWithGiftsPrices?.length === 1 ? '1' : plansWithGiftsPrices?.length === 2 ? '2' : '3',
              }}
              gap={{ base: 'large', bp3: 'small' }}>
              {plansWithGiftsPrices?.map(plan => {
                return (
                  <GiftPlan
                    planWithGiftsPrices={plan}
                    totalNumberOfPlans={
                      plansWithGiftsPrices.length === 1 ? 1 : plansWithGiftsPrices.length === 2 ? 2 : 3
                    }
                    userEmail={userEmail}
                    stripePublishableKey={stripePublishableKey}
                    customerServiceNumber={customerServiceNumber}
                  />
                )
              })}
            </CustomGrid>

            <SuccessOrFailModal customerServiceNumber={customerServiceNumber} />
          </>
        )}
      </Gutter>
      {accordion && (
        <Gutter>
          <Accordion {...accordion} variant="color" type="multiple" />
        </Gutter>
      )}
      {disclaimer && (
        <Gutter
          css={{
            backgroundColor: '$gs5',
          }}>
          <WysiwygReader
            initialValue={disclaimer}
            css={{
              maxWidth: '700px',
              mx: '$auto',
              '& p[data-wisiwig-body1="true"]': {
                fontSize: '$caption',
                fontFamily: '$caption',
                lineHeight: '$caption',
              },
            }}
          />
        </Gutter>
      )}
    </div>
  )
}

const planGiftCardsContainer = cva({
  base: {
    mt: '$6',
    justifyItems: 'center',
    bp1: {
      mt: '$8',
    },
    bp3: {
      mt: '$10',
    },
  },
  variants: {
    columns: {
      1: {
        justifyItems: 'center',
      },
      2: {
        justifyItems: 'center',
        bp3: {
          width: 'fit-content',
          mx: '$auto',
        },
      },
      3: {
        justifyItems: 'unset',
      },
    },
  },
})

const giftGifStyle = css({
  height: 'auto',
  width: '155px',
  bp1: {
    width: '170px',
  },
  bp2: {
    width: '200px',
  },
  bp3: {
    width: '220px',
  },
  '& svg path': {
    fill: '$pri',
  },
})

const topSection = {
  textAlign: 'center',
  mb: '40px',
  bp1: {
    mt: '20px',
    mb: '60px',
  },
  bp2: {
    mt: '30px',
    mb: '80px',
  },
  bp3: {
    mt: '40px',
    mb: '100px',
  },
} as const

const logoStyle = cva({
  base: {
    objectFit: 'contain',
    width: 'auto',
  },
  variants: {
    variant: {
      square: {
        height: '60px',
        bp2: {
          height: '72px',
        },
        bp3: {
          height: '84px',
        },
      },
      landscape: {
        height: '34px',
        bp2: {
          height: '38px',
        },
        bp3: {
          height: '41px',
        },
      },
      portrait: {
        height: '48px',
        bp2: {
          height: '58px',
        },
        bp3: {
          height: '67px',
        },
      },
    },
  },
})

const gutterStyled = cva({
  base: {
    backgroundColor: '$gs5',
    pb: '$10',
  },
  variants: {
    fixedBannerIsVisible: {
      true: {
        marginTop: '50px', // height linked to StarredPlan.tsx
      },
    },
  },
})
