'use client'

import { Button } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { useSession } from 'next-auth/react'
import { BlockPubThankYouCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import Link from 'next/link'
import { useSearchParams } from 'next/navigation'
import React from 'react'
import { css } from 'styled-system/css'

import { BlockViewProps } from '../../_utils/typescript-utils'

export const BlockPubThankYou: React.FC<
  BlockViewProps<{ ShapeOfCustomPropsDerivedFromPageData: BlockPubThankYouCustomPageData }>
> = props => {
  const { data: session, status } = useSession()
  const searchParams = useSearchParams()
  const from = searchParams.get('from')

  return (
    <Gutter
      className={css({ bgColor: '$gs3' })}
      variant="bare"
      data-sho-block-name="BlockPubThankYou"
      data-sho-block-id={props.blockId}>
      <CustomFlex direction={{ base: 'column', bp3: 'row' }} css={thankYouContainer}>
        <Gutter className={css({ width: '$full' })}>
          <CustomFlex align="center" justify="center" css={{ height: '$full' }}>
            <CustomFlex direction="column" align="center" justify="center" css={content}>
              <h1>
                <Text variant="h1" css={{ mb: '$12', maxWidth: '[514px]', color: '$gs12' }}>
                  Thank you for {from === 'one-time-donation' ? 'donating' : 'subscribing'}
                </Text>
              </h1>
              {status !== 'loading' && (
                <>
                  <Text variant="h4" css={{ mb: '$12', color: '$gs12' }}>
                    Your {from === 'one-time-donation' ? 'donation receipt' : 'subscription confirmation'} has been sent
                    to your email.
                    {status === 'unauthenticated' && from !== 'one-time-donation'
                      ? ' Please sign in to activate your subscription.'
                      : ''}
                  </Text>
                  <CustomFlex
                    direction="column"
                    align="center"
                    css={{ width: '$full', bp3: { width: '[500px]' }, '& a': { width: '$full' } }}>
                    <Link href={status === 'authenticated' ? '/' : '/sign-in'} prefetch={false}>
                      <Button
                        label={status === 'authenticated' ? 'Continue Reading' : 'Sign In'}
                        variant="primary"
                        fullWidth={true}
                      />
                    </Link>
                  </CustomFlex>
                </>
              )}
            </CustomFlex>
          </CustomFlex>
        </Gutter>
      </CustomFlex>
    </Gutter>
  )
}

const content = css.raw({
  margin: 'auto',
  textAlign: 'center',
  bp1: {
    maxWidth: '[800px]',
  },
})

const thankYouContainer = css.raw({
  background: '$gs3',
  position: 'relative',
  height: '$full',
  minHeight: '[400px]',
  overflow: 'auto',
  bp3: {
    overflow: 'hidden',
    minHeight: '[80vh]',
  },
})
