export type LmTribuneArchiveLinkQueryStringAdditionProps = {
  url: string
  blockId: string
  userIsSubscribed: boolean
  userIsLoggedIn: boolean
}

export const lmTribuneArchiveLinkQueryStringAddition = ({
  url,
  blockId,
  userIsSubscribed,
  userIsLoggedIn,
}: LmTribuneArchiveLinkQueryStringAdditionProps): string => {
  if (
    (blockId === 'block_017fbc26-9116-46bd-9eb3-786e7cf9c763' || // LM Tribune template block header main ID
      blockId === 'block_8b213634-6d2c-4ab4-9aa4-cae5503f6ded') && // DNews template block header main ID
    url === 'https://lmtribune.newsbank.com/'
  ) {
    if (!userIsLoggedIn) return url
    return `${url}?${userIsSubscribed ? 'd_dps=5900' : 'd_dps=0000'}`
  }
  return url
}
