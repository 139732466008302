'use client'

import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { useSession } from 'next-auth/react'
import { BlockPubLatestArticlesCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React from 'react'

import { MAX_NUMBER_OF_ARTICLES_LATEST_STORIES } from '@shared/constants'

import { isAdvertisementVisible } from '../../_utils/ad-utils'
import { adSystemOfSubscription } from '../../_utils/publication-utils'
import { BlockViewProps } from '../../_utils/typescript-utils'
import { RelatedArticlesTemplate } from '../RelatedArticlesTemplate'

export const BlockPubLatestArticles: React.FC<
  BlockViewProps<{ ShapeOfCustomPropsDerivedFromPageData: BlockPubLatestArticlesCustomPageData }>
> = props => {
  const {
    latestArticles,
    googleAdConfig,
    siteId,
    nextToken,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  } = props.blockCustomData
  const { data: session, status } = useSession()
  const adSettings = adSystemOfSubscription({
    status,
    session,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  })
  const googleAdIsVisible = isAdvertisementVisible({ googleAdConfig, adSettings })

  return (
    <Gutter data-sho-block-name="BlockPubLatestArticles" data-sho-block-id={props.blockId}>
      <h1>
        <Text variant="h6" css={{ color: '$gs11', display: 'block', mb: '$6', bp2: { mb: '$10' } }}>
          LATEST STORIES
        </Text>
      </h1>
      <RelatedArticlesTemplate
        articles={latestArticles}
        googleAdConfig={googleAdConfig}
        blockId={siteId}
        nextToken={nextToken}
        adIsVisible={googleAdIsVisible}
        type="additionalBlocks"
        maxNumberOfArticlesForInitialLoad={MAX_NUMBER_OF_ARTICLES_LATEST_STORIES}
      />
    </Gutter>
  )
}
