'use client'

import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { useSession } from 'next-auth/react'
import { BlockPubHomeTopStoriesCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React from 'react'
import { cva } from 'styled-system/css'

import { isAdvertisementVisible } from '../../_utils/ad-utils'
import { adSystemOfSubscription } from '../../_utils/publication-utils'
import { BlockViewProps } from '../../_utils/typescript-utils'
import { ArticlePreviewHorizontal } from '../ArticlePreviewHorizontal'
import { ArticlePreviewVerticalSmall } from '../ArticlePreviewVerticalSmall'
import { GoogleAd } from '../GoogleAd'

const collapsableId = 'pub-home-top-stories-ad'

export const BlockPubHomeTopStories: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockPubHomeTopStoriesCustomPageData
  }>
> = props => {
  const {
    pubHomeTopStoriesMainStories,
    pubHomeTopStoriesSecondaryStories,
    googleAdConfig,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  } = props.blockCustomData
  const { data: session, status } = useSession()
  const adSettings = adSystemOfSubscription({
    status,
    session,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  })
  const advertisementIsVisible = isAdvertisementVisible({ googleAdConfig, adSettings })
  const isTwoColumns = !!(advertisementIsVisible || pubHomeTopStoriesSecondaryStories?.length)

  return pubHomeTopStoriesMainStories?.length ? (
    <div data-sho-block-name="BlockPubHomeTopStories" data-sho-block-id={props.blockId}>
      <Gutter>
        <h1>
          <Text variant="h6" oll={false} css={titleText}>
            Top Stories
          </Text>
        </h1>
        <CustomFlex direction={{ base: 'column', bp2: 'row' }} gap="5">
          <CustomFlex
            direction={{ base: 'column', bp2: 'row' }}
            gap={'10'}
            className={articleContainer({
              isTwoColumns,
            })}>
            <CustomGrid columns={{ base: '1', bp1: '2', bp2: '1' }} gap={{ base: 'small', bp1: 'medium' }}>
              {pubHomeTopStoriesMainStories?.map((el, index) => (
                <CustomFlex key={el.articleHref} direction="column" gap={{ base: '6', bp1: '8' }}>
                  <ArticlePreviewHorizontal
                    {...el}
                    imgBoundInAspectRatio={isTwoColumns ? true : false}
                    sizes={`(min-width: 77.5em) 350px, (min-width: 56.5625em) ${
                      isTwoColumns ? 'min(calc(75vw - 84px), 750px)' : 'calc(100vw - 64px)'
                    }, (min-width: 37.5em) calc(50vw - 64px), calc(100vw - 48px)`}
                    placeholderSizes={`(min-width: 77.5em) 317px,  ${
                      isTwoColumns ? 'min(calc(75vw - 116px), 750px)' : 'calc(100vw - 96px)'
                    }, (min-width: 37.5em) calc(50vw - 96px), calc(100vw - 80px)`}
                    css={{ mb: '$0' }}
                  />
                  <CustomDivider
                    css={{
                      backgroundColor: '$gs7',
                      bp2: {
                        display: index < pubHomeTopStoriesMainStories.length - 1 ? 'block' : 'none',
                      },
                    }}
                  />
                </CustomFlex>
              ))}
            </CustomGrid>
          </CustomFlex>
          {isTwoColumns && (
            <CustomFlex direction="column" gap={{ base: '5', bp2: '10' }} css={adsContainer}>
              {advertisementIsVisible && (
                <CustomFlex direction="column" gap={'5'} id={collapsableId}>
                  <Text variant="overline" oll={false} css={{ textAlign: 'center', bp2: { textAlign: 'left' } }}>
                    Advertisement
                  </Text>

                  <div style={{ overflowX: 'auto' }}>
                    <GoogleAd
                      {...googleAdConfig}
                      collapsableId={collapsableId}
                      sizes="(min-width: 77.5em) 275px, (min-width: 77.5em) and (max-height: 720px) 215px , (min-width: 56.5625em) calc(25vw - 64px), (min-width: 37.5em) calc(100vw - 64px), calc(100vw - 48px)"
                      css={
                        googleAdConfig.adSystem === 'googleAdManager'
                          ? {
                              overflowX: 'auto',
                              transform: 'translateX(-2vw)',
                              bp2: {
                                overflowX: 'auto',
                                justifyContent: 'left',
                                transform: 'none',
                              },
                              bp3: { minWidth: '300px' },
                            }
                          : {}
                      }
                    />
                  </div>
                </CustomFlex>
              )}
              {!!pubHomeTopStoriesSecondaryStories?.length && (
                <div>
                  <Text variant={'h5'}>Trending Stories</Text>
                  {pubHomeTopStoriesSecondaryStories?.map(el => (
                    <div key={el.articleHref}>
                      <CustomDivider
                        css={{
                          backgroundColor: '$gs7',
                          my: '$3',
                          mx: '$0',
                        }}
                      />
                      <ArticlePreviewVerticalSmall {...el} hideImage={true} makeTitleTextSmall={true} />
                    </div>
                  ))}
                </div>
              )}
            </CustomFlex>
          )}
        </CustomFlex>
      </Gutter>
    </div>
  ) : null
}

const titleText = {
  color: '$gs12',
  pb: '10',
  bp2: {
    pb: '$12',
  },
} as const

const articleContainer = cva({
  base: {
    width: '$full',
    bp2: {
      order: 1,
    },
  },
  variants: {
    isTwoColumns: {
      true: {
        bp2: {
          width: '[75%]',
        },
      },
    },
    false: {},
  },
})

const adsContainer = {
  width: '$full',
  order: 1,
  bp2: {
    width: '[300px]',
    order: 2,
    position: 'sticky',
    top: '[173px]',
    height: 'fit',
  },
} as const
