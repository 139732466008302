'use client'

import { Box } from '@design-system/src/components/Box'
import { Gutter } from '@design-system/src/components/Gutter'
import { PdfViewer } from '@design-system/src/components/PdfViewer'
import { ShoImage } from '@design-system/src/components/ShoImage'
import { Text } from '@design-system/src/components/Text'
import { BlockPubArticleHeaderCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import { FC, useEffect, useRef } from 'react'
import { css, cva, cx } from 'styled-system/css'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperOptions } from 'swiper/types'

// declare Swiper custom elements
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'swiper-slide': any
      'swiper-container': any
    }
  }
}

type SwiperRef = HTMLElement & { swiper: Swiper; initialize: () => void }

type ArticleCarouselProps = {
  carousel: NonNullable<BlockPubArticleHeaderCustomPageData['carousel']>
}

export const ArticleCarousel: FC<ArticleCarouselProps> = ({ carousel }) => {
  const swiperRef = useRef<SwiperRef>(null)

  useEffect(() => {
    const intiateSwiper = async () => {
      const register = await import('swiper/element').then(mod => mod.register)
      //https://swiperjs.com/blog/using-swiper-element-in-react
      // Register Swiper web component
      register()
      // Object with parameters
      const params: SwiperOptions = {
        modules: [Navigation, Pagination],
        slidesPerView: 1,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
          bulletClass: 'swiper-pagination-bullet', // Default class for bullets
          bulletActiveClass: 'swiper-pagination-bullet-active', // Active bullet class
        },
        loop: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        // injectStyles: [swiperInjectedStyles], // Doesn't work. Perhaps it's Panda? No need to investigate further.
      }
      if (swiperRef && swiperRef.current) {
        // Assign it to swiper element
        Object.assign(swiperRef.current, params)
        // initialize swiper
        swiperRef.current.initialize()
      }
    }
    intiateSwiper()
  }, [])

  return (
    <div>
      <Box
        css={{
          width: '$full',
          position: 'relative',
          // mb: '$10',
          '& swiper-slide': { bgColor: '$gs3' },
          '& swiper-container': { bgColor: '$gs3' },
        }}>
        <swiper-container init="false" ref={swiperRef}>
          {carousel.map((slide, index) => {
            const isSlideFileAPdf = slide.key.endsWith('.pdf')
            return (
              <swiper-slide key={index}>
                <Box
                  css={{
                    backgroundColor: '$gs12',
                    width: '$full',
                    height: '$0',
                    position: 'relative',
                    paddingBottom: '[56.25%]',
                    bp3: {
                      paddingBottom: '[min(56.25%, 60vh)]',
                    },
                  }}>
                  <Gutter variant="bare" css={{ position: 'absolute', inset: '$0' }}>
                    {isSlideFileAPdf ? (
                      <div
                        className={css({
                          position: 'relative',
                          height: '$full',
                        })}>
                        <PdfViewer
                          assetKey={slide.key}
                          className={css.raw({
                            position: 'absolute',
                            inset: '[0px]',
                            width: '$full',
                            height: '$full',
                          })}
                        />
                      </div>
                    ) : (
                      <ShoImage
                        alt={slide.caption || `story image illustation`}
                        fill={true}
                        src={slide.key}
                        blurryPlaceholder={slide.blurryPlaceholder}
                        fillWrapperCss={css.raw({
                          height: '$full',
                          width: '$full',
                        })}
                        imageCss={css.raw({
                          objectFit: 'contain',
                        })}
                        priority={index === 0}
                        sizes="(min-width: 77.5em) 1200px, 100vw"
                      />
                    )}
                  </Gutter>
                </Box>
                {(slide.caption || slide.credits) && (
                  <Text
                    variant="caption"
                    css={{
                      color: '$gs12',
                      p: '$4',
                      display: 'block',
                      mx: '[auto]',
                      bgColor: '$gs2',
                      backgroundColor: '$gs3',
                    }}>
                    {slide.caption}
                    {slide.credits && <span className={css({ color: '$gs11', pl: '$3' })}>{slide.credits}</span>}
                  </Text>
                )}
              </swiper-slide>
            )
          })}
        </swiper-container>

        <div
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
          className={cx(arrowStyle({ direction: 'left' }), 'swiper-button-prev')}
        />
        <div
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
          className={cx(arrowStyle({ direction: 'right' }), 'swiper-button-next')}
        />
      </Box>
      <Gutter variant={'short'}>
        <div className={cx('swiper-pagination', swiperPagination)} />
      </Gutter>
    </div>
  )
}

const swiperPagination = css({
  zIndex: '1',
  display: 'flex',
  gap: '$2',
  flexWrap: 'wrap',
  justifyContent: 'center',
  '& .swiper-pagination-bullet': {
    cursor: 'pointer',
    display: 'inline-block',
    width: '$3',
    minWidth: '$3',
    height: '$3',
    backgroundColor: '$gs2',
    borderColor: '$gs12',
    borderRadius: '50%',
    borderWidth: '1px',
    borderStyle: 'solid',
    opacity: '1',
    transition: 'opacity 0.2s ease-in-out',
  },
  '& .swiper-pagination-bullet.swiper-pagination-bullet-active': {
    backgroundColor: '$gs12',
    borderColor: '$gs12',
    cursor: 'default',
  },
})

export const arrowStyle = cva({
  base: {
    cursor: 'pointer',
    backgroundColor: '$gs12',
    borderRadius: '$round',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    position: 'absolute',
    top: '[50%]',
    zIndex: '[1]',
    transform: 'translateY(-50%)',
    width: '[30px]',
    height: '[30px]',
    bp1: {
      width: '[40px]',
      height: '[40px]',
    },
    bp2: {
      width: '[50px]',
      height: '[50px]',
    },
    bp3: {
      width: '[60px]',
      height: '[60px]',
    },
    _after: {
      content: '" "',
      borderStyle: 'solid',
      borderColor: '$gs3',
      borderTopWidth: '$0',
      borderRightWidth: '[2px]',
      borderBottomWidth: '[2px]',
      borderLeftWidth: '$0',
      display: 'inline-block',
      transitionProperty: 'transform',
      transitionDuration: '$normal',
      transitionTimingFunction: 'in-out',
      padding: '[3px]',
      bp1: {
        padding: '[4px]',
      },
      bp2: {
        padding: '[5px]',
      },
      bp3: {
        padding: '[6px]',
      },
    },
  },
  variants: {
    direction: {
      left: {
        left: '[min(2vw, 40px)]',
        _after: {
          transform: 'rotate(135deg)',
        },
        _hover: {
          _after: {
            transform: 'scale(1.4) rotate(135deg)',
          },
        },
      },
      right: {
        right: '[min(2vw, 40px)]',
        _after: {
          transform: 'rotate(-45deg)',
        },
        _hover: {
          _after: {
            transform: 'scale(1.4) rotate(-45deg)',
          },
        },
      },
    },
    isVisible: {
      true: {
        display: 'flex',
      },
      false: {
        display: 'none',
      },
    },
  },
})

// export const swiperInjectedStyles = `
// .swiper-button-next, .swiper-button-prev {
//   cursor: pointer;
//   width: 60px;
//   height: 60px;
//   background-color: var(--pubgen--colors-gs12);
//   border-radius: 50%;
// },
// .swiper-button-next {
//   right: min(2vw, 40px) !important;
// },
// .swiper-button-prev {
//   left: min(2vw, 40px) !important;
// }
// .swiper-button-next svg, .swiper-button-prev svg {
// width: 24px;
// height: 24px;
//   color: var(--pubgen--colors-gs1);
// }
// .swiper-button-next:hover, .swiper-button-prev:hover {
//   background-color: var(--pubgen--colors-gs11);
//   transition: background-color 0.2s ease-in-out;
// }

// .swiper-pagination {
//   position: absolute;
//   z-index: 1;
//   top: 100%;
//   left: 50%;
//   transform: translateX(-50%);
//   display: flex;
//   gap: 8px;
// }
// .swiper-pagination-bullet {
//   cursor: pointer;
//   display: inline-block;
//   width: 12px;
//   height: 12px;
//   background-color: var(--pubgen--colors-gs2);
//   border-color: var(--pubgen--colors-gs12);
//   border-radius: 0%;
//   border-width: 1px;
//   border-style: solid;
//   opacity: 1;
//   transition: opacity 0.2s ease-inpout;
// }
// .swiper-pagination-bullet.swiper-pagination-bullet-active {
//   background-color: var(--pubgen--colors-gs12);
//   border-color: var(--pubgen--colors-gs12);
//   cursor: default;
// }
//   `
