'use client'

import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { Icon } from '@design-system/src/components/Icon'
import { Text } from '@design-system/src/components/Text'
import { ChevronDownIcon, ChevronUpIcon, Cross2Icon } from '@radix-ui/react-icons'
import React, { useEffect, useState } from 'react'
import { css } from 'styled-system/css'

import { BlockPubWeatherAdvisoryCustomPageData, BlockViewProps } from '../../_utils/typescript-utils'

const WEATHER_ADVISORY_LOCALSTORAGE_KEY = 'weather-advisory'

export const BlockPubWeatherAdvisory: React.FC<
  BlockViewProps<{ ShapeOfCustomPropsDerivedFromPageData: BlockPubWeatherAdvisoryCustomPageData }>
> = props => {
  const { message, weatherSiteUrl, instructions, description, timestamp, expires, id } = props.blockCustomData
  const [isVisible, setIsVisible] = useState(false)
  const [isExpanded, setIsExpanded] = useState(false)
  const [timeAgoString, setTimeAgoString] = useState<string | undefined>(timestamp ? getTimeAgo(timestamp) : undefined)

  useEffect(() => {
    if (id && message) {
      const storedId = localStorage.getItem(WEATHER_ADVISORY_LOCALSTORAGE_KEY)
      if (storedId) {
        // if there is a localstorage dismissal for the advisory, but the id is different, show the advisory as it's a different message
        if (id !== storedId) setIsVisible(true)
      } else {
        setIsVisible(true)
      }
    }
  }, [id])

  useEffect(() => {
    // Update immediately
    if (timestamp) {
      setTimeAgoString(getTimeAgo(timestamp))
    }

    // Set up interval to update every 5 minutes
    const interval = setInterval(
      () => {
        if (expires && new Date() > new Date(expires)) {
          setIsVisible(false)
          clearInterval(interval)
        }
        if (timestamp) {
          setTimeAgoString(getTimeAgo(timestamp))
        }
      },
      1 * 60 * 1000,
    ) // 1 minutes in milliseconds

    // Clean up interval on component unmount
    return () => clearInterval(interval)
  }, [timestamp, expires])

  return isVisible ? (
    <Gutter className={container} data-sho-block-name="BlockPubWeatherAdvisory" variant={'short'}>
      <CustomFlex align="baseline" justify="between" css={messageSection} gap={'10'}>
        <CustomFlex wrap={'noWrap'} align={'start'} gap={'3'}>
          {/* <Icon reactIcon={<ExclamationTriangleIcon />} css={{ color: '$w1' }} size={'30'} /> */}
          <CustomFlex direction={'column'} gap={'5'}>
            <CustomFlex align={'start'} gap={'1'} direction={'column'}>
              <Text
                css={{
                  alignItems: 'start',
                  textAlign: 'left',
                }}
                variant="subtitle2"
                oll={false}>
                {message}
              </Text>
              {timeAgoString && (
                <Text
                  variant="caption"
                  css={{
                    opacity: '0.75',
                  }}>
                  {timeAgoString}
                </Text>
              )}
              {(description || instructions) && (
                <Text
                  variant="caption"
                  css={{ textAlign: 'left', cursor: 'pointer' }}
                  isLink={true}
                  onClick={() => {
                    setIsExpanded(!isExpanded)
                  }}>
                  See {isExpanded ? 'less' : 'more'} information
                  <Icon
                    reactIcon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
                    size={'16'}
                    pointer={true}
                    css={{
                      p: '2px',
                      px: '$1',
                      ml: '$1',
                      borderRadius: '$2',
                      transform: 'translateY(3px)',
                    }}
                  />
                </Text>
              )}
            </CustomFlex>
            {isExpanded && (
              <>
                {description && (
                  <Text variant="body2" css={{ textAlign: 'left' }}>
                    <span dangerouslySetInnerHTML={{ __html: description }} />
                  </Text>
                )}
                {instructions && (
                  <Text variant="body2" css={{ textAlign: 'left' }}>
                    Instructions: {instructions}
                  </Text>
                )}
              </>
            )}
          </CustomFlex>
        </CustomFlex>
        <CustomFlex
          align={'center'}
          justify={'end'}
          gap={'2'}
          css={{
            width: 'fit-content',
          }}>
          <Text
            variant="caption"
            css={{
              display: 'none',
              whiteSpace: 'nowrap',
              bp1: {
                display: 'block',
              },
            }}>
            issued by the National Weather Service
          </Text>
          <Icon
            reactIcon={<Cross2Icon />}
            size={'14'}
            className={IconStyled}
            onClick={() => {
              if (typeof window !== 'undefined' && id) {
                localStorage.setItem(WEATHER_ADVISORY_LOCALSTORAGE_KEY, id)
              }
              setIsVisible(false)
            }}
          />
        </CustomFlex>
      </CustomFlex>
    </Gutter>
  ) : null
}

const getTimeAgo = (timestamp: string) => {
  // if timestamp is more than 1 hour ago, return in hours and mins
  // if timestamp is less than 1 hour ago, return in mins
  const now = new Date()
  const then = new Date(timestamp)
  const diff = now.getTime() - then.getTime()
  if (diff > 1000 * 60 * 60) {
    return `${Math.round(diff / (1000 * 60 * 60))}h ${Math.round(diff / (1000 * 60)) % 60}min ago`
  }
  return `${Math.round(diff / (1000 * 60))}min ago`
}

const container = css({
  backgroundColor: '$ale',
  width: '$full',
  margin: '0 auto',
  opacity: 0.95,
})

const IconStyled = css({
  color: '$gs12',
  backgroundColor: '$gs1',
  borderRadius: '100%',
  p: '$1',
  _hover: {
    opacity: 0.85,
    transitionDuration: '$normal',
    transitionProperty: 'opacity',
    transitionTimingFunction: 'ease-in-out',
  },
})

const messageSection = css.raw({
  position: 'relative',
  textAlign: 'center',
  margin: 'auto',
  color: '$w1',
  '& a': {
    color: '$w1',
  },
})
