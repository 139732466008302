'use client'

import { Box } from '@design-system/src/components/Box'
import { Button } from '@design-system/src/components/Button'
import { CustomDivider } from '@design-system/src/components/CustomDivider'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { Gutter } from '@design-system/src/components/Gutter'
import { ShoImage } from '@design-system/src/components/ShoImage'
import { Tag } from '@design-system/src/components/Tag'
import { Text } from '@design-system/src/components/Text'
import { useSession } from 'next-auth/react'
import type { BlockPubArticleBodyCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import Link from 'next/link'
import React from 'react'
import { css } from 'styled-system/css'

import type { WysiwygElement } from '@models/wysiwyg-types'

import { isAdvertisementVisible } from '../../../_utils/ad-utils'
import { adSystemOfSubscription } from '../../../_utils/publication-utils'
import { BlockViewProps } from '../../../_utils/typescript-utils'
import { GoogleAd } from '../../GoogleAd'
import { ArticleText } from './ArticleText'
import { RelatedArticles } from './RelatedArticles'

const collapsableId = 'pub-article-body-ad'

export const BlockPubArticleBody: React.FC<
  BlockViewProps<{
    ShapeOfCustomPropsDerivedFromPageData: BlockPubArticleBodyCustomPageData
  }>
> = props => {
  const {
    articleContent: articleContentProp,
    articleType,
    emailCaptureConfig,
    googleAdConfig,
    paidPostAdTitle,
    paidPostUrl,
    paidPostImgSrc,
    paidPostImgHeight,
    paidPostImgWidth,
    relatedArticles,
    sponsorCaption,
    sponsorImageSrc,
    sponsorImgWidth,
    sponsorImgHeight,
    sponsorUrl,
    tags,
    facebookHref,
    facebookColorScheme,
    facebookNumPosts,
    showComments,
    connectedSubscriptionSystem,
    stripePlans,
    stripeSupportPlans,
  } = props.blockCustomData
  const articleContent = typeof articleContentProp === 'string' ? JSON.parse(articleContentProp) : articleContentProp
  const { data: session, status } = useSession()
  const adSettings = adSystemOfSubscription({
    status,
    session,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  })

  const { newsletterId } = emailCaptureConfig

  const articleMidpoint = articleContent ? Math.floor(articleContent.length / 2) : 0
  const content = articleContent satisfies WysiwygElement[]
  const characterCount = content.reduce((acc, curr) => {
    if ('children' in curr) {
      for (const child of curr.children) {
        if ('text' in child) {
          acc += child?.text?.length || 0
        }
      }
    }
    return acc
  }, 0)

  const isShortArticle = characterCount < 500
  const relatedArticlesIsVisible = articleType !== 'paid' && !isShortArticle && !!relatedArticles?.length

  const advertisementIsVisible = isAdvertisementVisible({ googleAdConfig, adSettings })
  const rightColumnIsVisible =
    articleType !== 'paid' && !isShortArticle && (advertisementIsVisible || relatedArticlesIsVisible)

  return (
    <Gutter data-sho-block-name="BlockPubArticleBody" data-sho-block-id={props.blockId}>
      <CustomFlex
        justify={{ base: 'center', bp3: 'between' }}
        direction={{ base: 'column', bp3: 'row' }}
        gap={{ bp3: '5' }}>
        <CustomFlex
          direction="column"
          justify="start"
          align={{ base: 'center', bp3: 'start' }}
          css={{
            textWrap: '[pretty]',
            overflowWrap: 'break-word',
            maxWidth: '[750px]',
            margin: '[0 auto]',
            width: '$full',
            bp3: {
              width: '[620px]',
              my: '$0',
              ml: '$auto',
              mr: rightColumnIsVisible ? '$9' : '$auto',
              borderLeftWidth: '$1',
              borderRightWidth: '$1',
              borderStyle: 'solid',
              borderColor: '$gs6',
              py: '$0',
              px: '$4',
            },
          }}>
          <ArticleText
            articleMidpoint={articleMidpoint}
            articleContent={articleContent}
            articleType={articleType}
            emailCaptureConfig={emailCaptureConfig}
            newsletterId={newsletterId}
          />

          {articleType !== 'paid' && !!tags?.length && (
            <CustomFlex direction="column">
              <CustomDivider orientation="horizontal" size="medium" css={{ color: '$gs7', mt: '$10', mb: '$8' }} />
              <Text variant="overline" css={{ color: '$gs11', mb: '$4' }}>
                Story Tags
              </Text>
              <CustomFlex gap={'2'} wrap="wrap">
                {tags?.map(tag => (
                  <Link href={tag.link} key={tag.link} prefetch={false}>
                    <Tag id={tag.link} label={tag.title} isSelected={false} type={'default'} />
                  </Link>
                ))}
              </CustomFlex>
            </CustomFlex>
          )}

          {articleType === 'paid' && (
            <CustomFlex direction="column" justify="center" align="center" css={{ mt: '$10' }}>
              <Text variant="h4" css={{ color: '$gs12', textAlign: 'center', mb: '$10' }}>
                {paidPostAdTitle}
              </Text>
              {paidPostImgSrc && paidPostImgWidth && paidPostImgHeight && (
                <a href={paidPostUrl || undefined} target="_blank" rel="noopener noreferrer">
                  <ShoImage
                    alt="paid post image"
                    src={paidPostImgSrc}
                    width={paidPostImgWidth}
                    height={paidPostImgHeight}
                    imageCss={css.raw({
                      display: 'block',
                      width: 'auto',
                      height: 'auto',
                      maxWidth: '[300px]',
                      maxHeight: '[300px]',
                      marginTop: '$0',
                      mx: '$auto',
                      mb: '$10',
                      bp3: {
                        maxWidth: '[400px]',
                        maxHeight: '[400px]',
                      },
                    })}
                    sizes="(min-width: 77.5em) 400px, 300px"
                  />
                </a>
              )}
              <a href={paidPostUrl || undefined} target="_blank" rel="noopener noreferrer">
                <Button
                  variant="primary"
                  label="Learn More"
                  fullWidth={true}
                  css={{
                    mt: '$5',
                    bp3: {
                      width: '[fit-content !important]',
                    },
                  }}
                />
              </a>
            </CustomFlex>
          )}
          <CustomDivider
            orientation="horizontal"
            size="small"
            css={{
              display:
                rightColumnIsVisible && !(articleType === 'default' && advertisementIsVisible) ? 'block' : 'none',
              color: '$gs7',
              mt: '$8',
              mb: '$8',
              bp3: {
                display: 'none',
              },
            }}
          />
        </CustomFlex>

        {rightColumnIsVisible && (
          <CustomFlex
            direction="column"
            css={{
              width: '$full',
              maxWidth: articleType === 'default' ? '[400px]' : '$full',
              margin: '[0 auto]',
              bp3: {
                margin: '$0',
                width: '[max(22%, 275px)]',
                position: 'sticky',
                top: '[146px]',
                height: 'fit',
              },
              '@media print': {
                display: 'none',
              },
            }}>
            {articleType === 'default' && advertisementIsVisible && (
              <Box
                id={collapsableId}
                css={{
                  display: 'none',
                  bp3: {
                    display: 'block',
                  },
                }}>
                <CustomDivider orientation="horizontal" size="medium" css={{ color: '$gs7', mb: '$4' }} />
                <Text css={rightColumnText} variant="overline">
                  Advertisement
                </Text>
                <GoogleAd
                  {...googleAdConfig}
                  collapsableId={collapsableId}
                  css={{
                    mb: '$8',
                  }}
                />
              </Box>
            )}
            {articleType === 'sponsored' && sponsorImageSrc && sponsorImgWidth && sponsorImgHeight && (
              <>
                <CustomDivider orientation="horizontal" size="medium" css={{ color: '$gs7', mb: '$4' }} />
                <Text css={rightColumnText} variant="overline">
                  Story Sponsor
                </Text>
                <a href={sponsorUrl || undefined} target="_blank" rel="noopener noreferrer">
                  <ShoImage
                    alt="sponsor logo"
                    src={sponsorImageSrc}
                    width={sponsorImgWidth}
                    height={sponsorImgHeight}
                    imageCss={css.raw({
                      display: 'block',
                      width: 'auto',
                      height: 'auto',
                      maxWidth: '$full',
                      maxHeight: '[40vh]',
                      margin: '[0 auto]',
                    })}
                    sizes="(min-width: 77.5em) 250px, 100vw"
                  />
                </a>
              </>
            )}
            {articleType === 'sponsored' && sponsorCaption && (
              <a href={sponsorUrl || undefined} target="_blank" rel="noopener noreferrer">
                <Text
                  variant="caption"
                  css={{
                    color: '$gs11',
                    mb: '$4',
                    mt: '$2',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    textAlign: 'center',
                    bp3: {
                      textAlign: 'left',
                      marginLeft: '$0',
                      marginRight: '$0',
                    },
                  }}>
                  {sponsorCaption}
                </Text>
              </a>
            )}
            {relatedArticlesIsVisible && <RelatedArticles relatedArticles={relatedArticles} />}
          </CustomFlex>
        )}
      </CustomFlex>
      {showComments && facebookHref && (
        <div
          className="fb-comments"
          data-href={facebookHref}
          data-width="100%"
          data-lazy="true"
          data-numposts={`${facebookNumPosts}`}
          data-colorscheme={facebookColorScheme}></div>
      )}
    </Gutter>
  )
}

const rightColumnText = css.raw({
  color: '$gs11',
  pb: '$4',
  textAlign: 'center',
  marginLeft: 'auto',
  marginRight: 'auto',
  bp3: {
    textAlign: 'left',
    marginLeft: '$0',
    marginRight: '$0',
  },
})
