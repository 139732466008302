'use client'

import { Button } from '@design-system/src/components/Button'
import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { CustomGrid } from '@design-system/src/components/CustomGrid'
import { Gutter } from '@design-system/src/components/Gutter'
import { Text } from '@design-system/src/components/Text'
import { isOneMonthOverFromNow } from '@design-system/src/utils/date-utils'
import { PAGES_NEXT_PUBLIC_SITES } from '@next-public-site/src/utils/route-utils'
import { useSession } from 'next-auth/react'
import { BlockPubPaywallCustomPageData } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import React, { useEffect, useState } from 'react'

import { doesPublicationHaveAvailablePlans, isUserSubscribedToPublication } from '../../_utils/publication-utils'
import { BlockViewProps } from '../../_utils/typescript-utils'

const ARTICLE_COUNT_LOCAL_STORAGE_KEY = 'articleCount'

export const BlockPubPaywallArticle: React.FC<
  BlockViewProps<{ ShapeOfCustomPropsDerivedFromPageData: BlockPubPaywallCustomPageData }>
> = props => {
  const {
    articleAccess,
    numberOfFreeArticlesAllowedPerMonth,
    subscriptionPlansUrl,
    connectedSubscriptionSystem,
    stripeSupportPlans,
    stripePlans,
    articleReleaseDate,
    meteredPaywallDisplayUpperLimit = 2,
  } = props.blockCustomData
  const [numberOfFreeArticlesRemaining, setNumberOfFreeArticlesRemaining] = useState<string>()
  const { data: session, status } = useSession()
  const isUserSubscribed = isUserSubscribedToPublication({
    status,
    session,
    stripePlans,
    stripeSupportPlans,
    connectedSubscriptionSystem,
  })
  const publicationHasAvailablePlans = doesPublicationHaveAvailablePlans({
    stripePlans,
    stripeSupportPlans,
    propayPlans: [],
    connectedSubscriptionSystem,
  })

  const [continueReading, setContinueReading] = useState(false)
  const [viewType, setViewType] = useState<
    | 'limit_article'
    | 'limit_article_no_plans'
    | 'free_articles_left'
    | 'free_articles_left_no_plans'
    | 'subscribed_article'
    | 'none'
  >('none')

  const changePubArticleBodyAndBodyDisplay = (option: 'view' | 'hide'): void => {
    const el = document.querySelector<HTMLElement>('[data-sho-block-name="Article Body"]')
    const elBody = document.querySelector('body')
    if (el) {
      if (option === 'hide') {
        el.style.display = 'none'
      } else {
        el.style.display = 'block'
      }
    } else {
      console.warn('cannot find Article Body')
    }

    if (elBody) {
      if (option === 'hide') {
        elBody.style.overflow = 'hidden'
      } else {
        elBody.style.overflow = 'auto'
      }
    } else {
      console.warn('cannot find Article Body')
    }
  }

  useEffect(() => {
    return () => {
      const elBody = document.querySelector('body')
      if (elBody) {
        elBody.style.overflow = 'auto'
      }
    }
  }, [])

  // Determining the the UI of the paywall
  useEffect(() => {
    const getViewType = () => {
      switch (articleAccess) {
        case 'Public':
          // console.log('return a')
          changePubArticleBodyAndBodyDisplay('view')
          // Public articles never trigger a paywall
          return 'none'

        case 'Subscribed':
          if (isUserSubscribed) {
            // console.log('return b-1')
            changePubArticleBodyAndBodyDisplay('view')
            return 'none'
          } else {
            changePubArticleBodyAndBodyDisplay('hide')
            // console.log('return b-2')
            return 'subscribed_article'
          }

        case 'Metered':
          // If the publication does not have available plans (subscription not possible) and the user is authenticated, allow them to continue reading
          if (!publicationHasAvailablePlans && status !== 'unauthenticated') {
            changePubArticleBodyAndBodyDisplay('view')
            return 'none'
          }

          if (isUserSubscribed) {
            changePubArticleBodyAndBodyDisplay('view')
            // console.log('return c-1')
            return 'none'
          }

          // Metered articles can hit either the Articles Limit or Free Articles Left paywalls

          // If article is metered and is over 30 days old, it automatically hits the 'subscribed' paywall
          if (articleReleaseDate && isOneMonthOverFromNow(articleReleaseDate)) {
            changePubArticleBodyAndBodyDisplay('hide')
            // console.log('return c-2')
            return publicationHasAvailablePlans ? 'subscribed_article' : 'free_articles_left_no_plans'
          }

          if (typeof window !== 'undefined' && localStorage.getItem(ARTICLE_COUNT_LOCAL_STORAGE_KEY)) {
            const articleCount = JSON.parse(localStorage.getItem(ARTICLE_COUNT_LOCAL_STORAGE_KEY) as string)
            const savedMonthYear = `${new Date(articleCount.date).getMonth()}-${new Date(
              articleCount.date,
            ).getFullYear()}`
            const currentMonthYear = `${new Date().getMonth()}-${new Date().getFullYear()}`

            // User is is entering a new month cycle. Reseting number of free articles allowed
            if (savedMonthYear !== currentMonthYear) {
              const data = {
                articlesLeft: numberOfFreeArticlesAllowedPerMonth,
                date: new Date(),
              }
              localStorage.setItem(ARTICLE_COUNT_LOCAL_STORAGE_KEY, JSON.stringify(data))
              if (numberOfFreeArticlesAllowedPerMonth > meteredPaywallDisplayUpperLimit) {
                // console.log('return c-3')
                changePubArticleBodyAndBodyDisplay('view')
                return 'none'
              } else {
                // console.log('return c-4')
                changePubArticleBodyAndBodyDisplay('hide')
                return publicationHasAvailablePlans ? 'free_articles_left' : 'free_articles_left_no_plans'
              }
            } else {
              if (articleCount.articlesLeft > meteredPaywallDisplayUpperLimit) {
                // console.log('return c-5')
                decreaseNumberOfFreeArticlesPerMonth()
                changePubArticleBodyAndBodyDisplay('view')
                return 'none'
              } else if (articleCount.articlesLeft > 0) {
                changePubArticleBodyAndBodyDisplay('hide')
                // console.log('return c-6')
                return publicationHasAvailablePlans ? 'free_articles_left' : 'free_articles_left_no_plans'
              } else {
                // console.log('return c-7')
                changePubArticleBodyAndBodyDisplay('hide')
                return publicationHasAvailablePlans ? 'limit_article' : 'limit_article_no_plans'
              }
            }
          }
          // User is vewing an article for the first time
          else {
            const data = {
              articlesLeft: numberOfFreeArticlesAllowedPerMonth,
              date: new Date(),
            }
            localStorage.setItem(ARTICLE_COUNT_LOCAL_STORAGE_KEY, JSON.stringify(data))
            if (numberOfFreeArticlesAllowedPerMonth > meteredPaywallDisplayUpperLimit) {
              // console.log('return c-8')
              changePubArticleBodyAndBodyDisplay('view')
              return 'none'
            } else {
              changePubArticleBodyAndBodyDisplay('hide')
              // console.log('return c-9')
              return publicationHasAvailablePlans ? 'free_articles_left' : 'free_articles_left_no_plans'
            }
          }

        default:
          console.error('View type unable to be determined:', articleAccess, isUserSubscribed)
          return 'none'
      }
    }
    const viewType = getViewType()
    setViewType(viewType)

    // Set number of article left
    if (typeof window !== 'undefined' && localStorage.getItem(ARTICLE_COUNT_LOCAL_STORAGE_KEY)) {
      const articleObjInLocalStorage = localStorage.getItem(ARTICLE_COUNT_LOCAL_STORAGE_KEY)
      if (articleObjInLocalStorage) {
        const articleObjInLocalStorageParsed = JSON.parse(articleObjInLocalStorage as string)
        if (articleObjInLocalStorageParsed?.articlesLeft) {
          setNumberOfFreeArticlesRemaining(articleObjInLocalStorageParsed.articlesLeft)
        }
      }
    }
  }, [isUserSubscribed])

  const decreaseNumberOfFreeArticlesPerMonth = () => {
    changePubArticleBodyAndBodyDisplay('view')
    setContinueReading(true)
    if (localStorage.getItem(ARTICLE_COUNT_LOCAL_STORAGE_KEY)) {
      const articleCount = JSON.parse(localStorage.getItem(ARTICLE_COUNT_LOCAL_STORAGE_KEY) as string)
      articleCount.articlesLeft -= 1
      localStorage.removeItem(ARTICLE_COUNT_LOCAL_STORAGE_KEY)
      localStorage.setItem(ARTICLE_COUNT_LOCAL_STORAGE_KEY, JSON.stringify(articleCount))
    } else {
      // User is reading an article for the first time.
      // Need to set a counter localstorage.
      const data = {
        articlesLeft: numberOfFreeArticlesAllowedPerMonth,
        date: new Date(),
      }
      localStorage.setItem(ARTICLE_COUNT_LOCAL_STORAGE_KEY, JSON.stringify(data))
    }
  }

  if (status === 'loading') return null

  return viewType === 'none' || continueReading ? null : (
    <CustomFlex
      css={{
        zIndex: '[1100]',
        position: 'fixed',
        bottom: '$0',
        left: '$0',
        overflow: 'auto',
        maxHeight: '[calc(100dvh - 122px)]',
      }}
      direction="column"
      data-sho-block-name="BlockPubPaywallArticle">
      <CustomFlex css={overlay} />
      <CustomFlex css={band} />
      <Gutter css={{ backgroundColor: '$gs1' }}>
        <CustomFlex css={{ margin: 'auto' }} direction="column" align="center" gap={'6'}>
          {viewType === 'subscribed_article' ? (
            <>
              <Text css={articleText} variant="h2">
                Subscribe to access this story.
              </Text>
              <Text css={articleText} variant="body1">
                Subscribe now for unlimited stories. Cancel anytime.
              </Text>
              <Button
                variant="primary"
                label="See Subscription Plans"
                fullWidth={true}
                css={buttonOverride}
                routeOnClick={PAGES_NEXT_PUBLIC_SITES.subscribe}
              />
            </>
          ) : viewType === 'free_articles_left' ? (
            <>
              <Text css={articleText} variant="h2">
                You&apos;re running out of free&nbsp;stories.
              </Text>
              <Text css={articleText} variant="body1">
                Subscribe now for unlimited stories. Cancel&nbsp;anytime.
              </Text>
              <Text css={articleText} variant="body1">
                Free Stories Left: {numberOfFreeArticlesRemaining}.
              </Text>
              <CustomGrid columns={{ base: '1', bp2: '2' }} gap={'small'}>
                <Button
                  variant="tertiary"
                  label="Continue Viewing Stories."
                  fullWidth={true}
                  onClick={decreaseNumberOfFreeArticlesPerMonth}
                />
                <Button
                  variant="primary"
                  label="See Subscription Plans"
                  fullWidth={true}
                  routeOnClick={PAGES_NEXT_PUBLIC_SITES.subscribe}
                />
              </CustomGrid>
            </>
          ) : viewType === 'free_articles_left_no_plans' ? (
            <>
              <Text css={articleText} variant="h2">
                You&apos;re running out of free&nbsp;stories.
              </Text>
              <Text css={articleText} variant="body1">
                Sign in now for unlimited stories.
              </Text>
              <Text css={articleText} variant="body1">
                Free Stories Left: {numberOfFreeArticlesRemaining}.
              </Text>
              <CustomGrid columns={{ base: '1', bp2: '2' }} gap={'small'}>
                <Button
                  variant="tertiary"
                  label="Continue Viewing Stories."
                  fullWidth={true}
                  onClick={decreaseNumberOfFreeArticlesPerMonth}
                />
                <Button
                  variant="primary"
                  label="Sign In"
                  fullWidth={true}
                  routeOnClick={PAGES_NEXT_PUBLIC_SITES.signIn}
                />
              </CustomGrid>
            </>
          ) : viewType === 'limit_article_no_plans' ? (
            <>
              <Text css={articleText} variant="h2">
                You&apos;ve reached your story&nbsp;limit.
              </Text>
              <Text css={articleText} variant="body1">
                Sign in now for unlimited stories.
              </Text>
              <Button
                variant="primary"
                label="Sign In"
                fullWidth={true}
                css={buttonOverride}
                routeOnClick={PAGES_NEXT_PUBLIC_SITES.signIn}
              />
            </>
          ) : viewType === 'limit_article' ? (
            <>
              <Text css={articleText} variant="h2">
                You&apos;ve reached your story&nbsp;limit.
              </Text>
              <Text css={articleText} variant="body1">
                Subscribe now for unlimited stories. Cancel&nbsp;anytime.
              </Text>
              <Button
                variant="primary"
                label="See Subscription Plans"
                fullWidth={true}
                css={buttonOverride}
                routeOnClick={PAGES_NEXT_PUBLIC_SITES.subscribe}
              />
            </>
          ) : null}
        </CustomFlex>
      </Gutter>
    </CustomFlex>
  )
}

const buttonOverride = {
  bp3: {
    width: '[100% !important]',
  },
} as const

const overlay = {
  background: '[linear-gradient(360deg, {colors.$b1} 0%, {colors.$b2} 20%, {colors.$b7} 60%, {colors.$w12} 100%)]',
  height: '[100vh]',
} as const

const band = {
  height: '$4',
  backgroundColor: '$pri',
} as const

const articleText = {
  color: '$gs12',
  textAlign: 'center',
} as const
